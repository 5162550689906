import React, {Component} from 'react';
import '../../assets/css/theme/login-6.css'
import Background from '../../assets/images/bg-4.jpg'


class ConfirmationMail extends Component {



    updateInput = (e) => {
        e.preventDefault();
        this.setState({
            token: e.target.value
        });
    };

    render() {
        return (
            <div className="kt-grid kt-grid--ver kt-grid--root kt-page kt-grid-page">
                <div className="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v6 kt-login--signin"
                     id="kt_login">
                    <div
                        className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
                        <div
                            className="kt-grid__item  kt-grid__item--order-tablet-and-mobile-2  kt-grid kt-grid--hor kt-login__aside">
                            <div className="kt-login__wrapper">
                                <div className="kt-login__container">
                                    <div className="kt-login__body">
                                        <div className="kt-login__logo">
                                            <a href="!#">
                                                <img src={require('../../assets/images/MZ.png')}
                                                     className={"img-responsive p-0"} style={{width: "100%"}} alt=""/>
                                            </a>
                                        </div>
                                        <div className="kt-login__logo">
                                            <a href="!#">
                                                <img src={require('../../assets/images/cropped-cropped-e-health.jpg')}
                                                     className={"img-responsive"} style={{width: "50%"}} alt=""/>
                                                <img src={require('../../assets/images/Who-Europe_logo-1.jpg')}
                                                     className={"img-responsive"} style={{width: "50%"}} alt=""/>

                                            </a>
                                        </div>
                                        <div className="kt-login__signin">
                                            <div className="kt-login__head">
                                                <h3 className="kt-login__title">Успешно го креиравте вашиот профил!</h3>
                                                <div className="kt-login__desc">Активациски линк е испратен на вашата е-пошта за да го активирате профилот
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="kt-grid__item kt-grid__item--fluid kt-grid__item--center kt-grid kt-grid--ver kt-login__content"
                            style={{backgroundImage: "url(" + Background + ")"}}>
                            <div className="kt-login__section">
                                <div className="kt-login__block">
                                    <div className="kt-login__desc">
                                        <br/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default ConfirmationMail;