import React, {Component} from "react";
import {Route, Switch, Redirect, withRouter} from 'react-router-dom';
import './assets/css/theme/base.css';
import './assets/css/theme/login-6.css';
import './App.css';
import Login from "./components/Login/Login";
import Aux from "./hoc/AuxWrapper";
import {AUTH_TOKEN} from "./shared/utility";
import Nav from "./components/Navbar/Nav";
import Footer from "./components/Footer/Footer";
import Logout from "./pages/Logout";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import ChangePassword from "./components/ChangePassword/ChangePassword";
import Home from "./components/Home/Home";
import Registration from "./components/Registration/Registration";
import ConfirmationMail from "./components/ConfirmationEmail/ConfirmationЕmail";
import Courses from "./components/Courses/Courses";
import UserSelectedCourses from "./components/UserSelectedCourses/UserSelectedCourses"
import Statistics from "./components/Statistics/Statistics";
import UserUpload from "./components/Upload/UserUpload";
import Certificate from "./components/Certificate/Certificate";
import AddCourse from "./components/Courses/AddCourse";
import AdminAttachingDocumentForUser from "./components/UserSelectedCourses/AdminAttachingDocumentForUser";
import Category from "./components/Category/Category";
import AddCategory from "./components/Category/AddCategory";
import EditCategory from "./components/Category/EditCategory";
import {strings} from "./components/Localization/Localization";


class App extends Component {
    constructor(props) {
        super(props);
        const activeLanguage = localStorage.getItem('activeLanguage')
        if(activeLanguage === null || activeLanguage === undefined){
            localStorage.setItem('activeLanguage', 'mk')
            strings.setLanguage('mk')
        } else {
            strings.setLanguage(activeLanguage)
        }
        this.state = {
            role: "",
            data: {}

        }
    }

    render() {
        let routes = (
            <Switch>
                <Route path="/login" component={Login}/>
                <Route path="/registration" component={Registration}/>
                <Route path="/confirmationМail" component={ConfirmationMail}/>
                <Route path="/reset/password" exact component={ResetPassword}/>
                <Redirect to="/login"/>
            </Switch>
        );
        let authToken = localStorage.getItem(AUTH_TOKEN);
        let userRole = localStorage.getItem('userDetails');
        // let activeLanguage = localStorage.getItem('activeLanguage');
        if (authToken && authToken !== '') {
            if (userRole === "ROLE_ADMIN") {
                routes = this.adminRoutes();
            } else {
                routes = this.userRoutes();
            }
        }

        return (
            <Aux>
                {routes}
            </Aux>
        )
    }

    adminRoutes = () => {
        return (<Aux>
            <Nav title={<div style={{marginLeft:"300px"}}></div>}  />
            <div className={"container"}>
                <Switch>
                    <Route path="/" exact component={Courses}/>
                    <Route path="/login" component={Login}/>
                    <Route path="/logout" component={Logout}/>
                    <Route path="/changepassword" exact component={ChangePassword}/>
                    <Route path="/statistics" exact component={Statistics}/>
                    <Route path="/certificates" exact component={Certificate}/>
                    <Route path="/course/add" exact component={AddCourse}/>
                    <Route path="/users" exact component={AdminAttachingDocumentForUser}/>
                    <Route path="/category" exact component={Category}/>
                    <Route path="/category/add" exact component={AddCategory}/>
                    {/*<Route path="/category/update" exact component={EditCategory}/>*/}
                </Switch>
            </div>
            <Footer/>
        </Aux>);
    };


    userRoutes = () => {
        return (<Aux>
            <div className={""}>
                <Switch>
                    <Route path="/" exact component={Home}/>
                    <Route path="/login" component={Login}/>
                    <Route path="/logout" component={Logout}/>
                    <Route path="/changepassword" exact component={ChangePassword}/>
                    <Route path="/mycourses" exact component={UserSelectedCourses}/>
                    <Route path="/mycourses/:id" exact component={UserUpload}/>
                </Switch>
            </div>
            <Footer/>
        </Aux>)
    };
}

export default withRouter(App);

