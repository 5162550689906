import React, {Component} from 'react';
import axios from "../../axios/app";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import "./ChangePassword.css"
import Nav from "../Navbar/Nav";
import {strings} from "../Localization/Localization";



class ChangePassword extends Component {

    state = {
        psmatch: null,
        password: "",
        repeatPassword: ""
    };
    handlePassword = (e) => {
        this.setState({password: e.target.value})
    };
    handleRepeatPassword = (e) => {
        this.setState({repeatPassword: e.target.value})
    };
    changePassword = () => {
        let username = localStorage.getItem('userEmail');
        if (this.state.password === this.state.repeatPassword && this.state.password != '' && this.state.repeatPassword != '') {
            const form = new FormData();
            // form.append('username', username);
            form.append('password', this.state.password);
            form.append('repeatPassword', this.state.repeatPassword);
            axios.post('/rest/user/change_password', form).then(response => {
                toast.success(strings.successfulPassMessage)
                this.props.history.push("/");
            }).catch(err => {
                toast.error(strings.errorPassMessage)
            });
        } else {
            this.setState({
                psmatch: strings.invalidPassMessage
            });
            document.getElementById('changepassword').value = "";
            document.getElementById('repeatpassword').value = "";
        }
    };

    render() {
        return (
            <>
                {localStorage.getItem("userDetails")==="ROLE_USER"&& <Nav/> }

                <div className="container box-shadow" id="changeBorder">
<div>
    <h2 className="mt-4 mb-5 text-center mt-5" style={{color:"black"}} id="changePass"><br></br>{strings.changePassword}</h2></div>


                            <div className="container col-10 " style={{position:"relative",marginLeft:"145px"}}>
                        <div className="col-sm-3 col-lg-3 col-md-3 ">
                            <label htmlFor="changepassword" style={{color:"black",fontWeight:"bold",fontSize:"15px"}}>{strings.newPassword}</label>
                        </div>
                        <div className="col-sm-9 col-md-9 col-lg-9 mb-4">
                            <input name="changepassword" id="changepassword" type="password"
                                   className="form-control" onChange={(e) => this.handlePassword(e)} required/>
                        </div>
                        <div className="col-sm-5 col-lg-5 col-md-5 ">
                            <label htmlFor="repeatpassword" style={{color:"black",fontWeight:"bold",fontSize:"15px"}}>{strings.confirmNewPassword} </label>
                        </div>
                        <div className="col-sm-9 col-md-9 col-lg-9 mb-4">
                            <input name="repeatpassword" id="repeatpassword" type="password"
                                   className="form-control" onChange={(e) => this.handleRepeatPassword(e)} required/>
                        </div>
                        {this.state.psmatch == null ? <p></p> : <p style={{color: "red"}}>{this.state.psmatch}</p>}
                    </div>


                        <div className="row">
                            <div className="col-4"></div>
                            <button className="col-4 btn btn-brand btn-pill btn-elevate mb-3 button-35" id="promeniLozinka" style={{zIndex: "100"}} type="submit"
                                    onClick={this.changePassword}>{strings.change}
                            </button>
                            <div className="col-4"></div>
                    </div>


            </div>
            </>


        );
    }

}

export default ChangePassword