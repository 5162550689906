import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { ThreeDots } from "react-loader-spinner";
import { strings } from "../Localization/Localization";
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
    },
  })
);

export default function Loading(props) {
  const classes = useStyles();
  return (
    <div
      className={classes.root}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <ThreeDots type="ThreeDots" color="grey" height={80} width={80} />
      <p style={{ color: "grey", fontSize: "20px" }}>
        {strings.loadingCourses}
      </p>
    </div>
  );
}
