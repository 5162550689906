import React, {Component} from "react";
import ReactPaginate from 'react-paginate';
import WatermarkModal from "../Statistics/WatermarkModal";
import UserCourseRepository from "../../repository/UserCourseRepository";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {ButtonToolbar} from "react-bootstrap";
import DeleteAttachedCert from "./DeleteAttachedCert";
import moment from "moment";
import SingleWatermarkModal from "./SingleWatermarkModal";
import {faFilePdf, faStamp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {strings} from "../Localization/Localization";
import {OBUKI} from "../../shared/utility";

class Certificate extends Component {

    state = {
        countAttached: null,
        countVerified: null,
        points: [],
        ucIdsForWatermark: [],
        userCourses: [],
        pageCount: 1
    }
    singleWatermark = (ucId) => {
        return UserCourseRepository.singleWatermark(ucId).then(() => {
            toast.success(strings.successfulSignMessage)
            this.getCertificates();
            this.getUserCourseReadyForWatermark();
            this.fetchData();
        })
            .catch(() => toast.error(strings.errorSignMessage))
    }
    deleteAttachedCertificate = (ucId) => {
        return UserCourseRepository.deleteAttachedCertificate(ucId)
            .then(() => {
                toast.success(strings.successfulDeleteMessage)
                this.getCertificates();
                this.getUserCourseReadyForWatermark();
                this.fetchData();
            })
            .catch(() => toast.error(strings.errorDeleteMessage))
    }

    componentDidMount() {
        this.getCertificates();
        this.getUserCourseReadyForWatermark();
        this.fetchData();
    }

    fetchData = (selecetedPage = 0) => {
        UserCourseRepository.getAllUCattachedPaged(selecetedPage).then(res => {
            this.setState({
                userCourses: res.data.content,
                pageCount: res.data.totalPages
            })
        }).catch(err => {
            console.log(err)
        })
    }

    handlePageClick = data => {
        this.fetchData(data.selected);
    };
    getCertificates = () => {
        UserCourseRepository.attachedCertificates().then(res => {
            this.setState({countAttached: res.data})
        }).catch((err) => {
            console.log(err)
        })
        UserCourseRepository.verifiedCertificates().then(res => {
            this.setState({countVerified: res.data})
        }).catch((err) => {
            console.log(err)
        })
    }

    getUserCourseReadyForWatermark = () => {
        UserCourseRepository.getAllUCforWatermark().then(res => {
            this.setState({ucIdsForWatermark: res.data})
        })
    }
    watermark = () => {
        let ucIds = [];
        let points = [];
        this.state.ucIdsForWatermark.map(item => ucIds.push(item.id));
        this.state.ucIdsForWatermark.map(item => points.push(item.coursePoints));
        debugger
        return UserCourseRepository.watermark(ucIds.toString(), points.toString()).then(() => {
            toast.success(strings.successfulSignCertificates)
            this.getCertificates();
            this.getUserCourseReadyForWatermark();
            this.fetchData();
        }).catch(() => {
            toast.error(strings.errorSignCertificates)
        })
    }

    render() {
        return (
            <div className="container">
                <div className="row mt-5">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <label>{strings.uploadedCertificate} {this.state.countAttached}</label>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <label>{strings.receivedCertificate} {this.state.countVerified}</label>
                    </div>
                </div>
                <div className="row">

                    <div className="col-lg-5 col-md-5 col-sm-5">
                        {this.state.ucIdsForWatermark.length !== 0 ? <WatermarkModal btnClass={"ml-1 roundEdge"}
                                                                                     prompt={strings.areYouSureMessage}
                                                                                     watermark={this.watermark}/> :
                            <button disabled={true} type="button"
                                    className="btn btn-info btn-sm ml-1 mt-2 roundEdge"><FontAwesomeIcon icon={faStamp}
                                                                                                         style={{paddingRight: '4px'}}/>{strings.sign}
                            </button>}
                    </div>
                </div>
                <div className="row mt-3">
                    <table className="table table-hover">
                        <thead>
                        <tr>
                            <th>{strings.name}</th>
                            <th>{strings.lastname}</th>
                            <th>{strings.course}</th>
                            <th>{strings.uploadDate}</th>
                            <th className="text-center">{strings.actions}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.userCourses.map(this.renderAttachedCert)}
                        </tbody>
                    </table>
                    <div>
                        <ReactPaginate
                            previousLabel={strings.previous}
                            nextLabel={strings.next}
                            breakLabel={'...'}
                            pageCount={this.state.pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick}
                            breakClassName={'page-item'}
                            breakLinkClassName={'page-link'}
                            containerClassName={'pagination'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            activeClassName={'active'}
                        />
                    </div>
                </div>
            </div>
        )
    }

    renderAttachedCert = (uc) => {
        return (<tr key={uc[0]}>
            <td>{uc[3]}</td>
            <td>{uc[4]}</td>
            <td>{uc[5]}</td>
            <td>{moment(uc[1]).local().format('YYYY-MM-DD HH:mm:ss')}</td>
            <td><ButtonToolbar style={{display: "flex", flexFlow: "column wrap"}}><a href={`${OBUKI}/rest/attachment/certificate/${uc[2]}`} target="_blank"
                                  rel="noopener noreferrer" className="btn btn-sm btn-dark ml-2"><FontAwesomeIcon
                icon={faFilePdf} style={{paddingRight: '4px'}}/>{strings.open}</a>
                <SingleWatermarkModal prompt={strings.areYouSureMessageSign}
                                      singleWatermark={() => this.singleWatermark(uc[0])}/>

                <DeleteAttachedCert prompt={strings.areYouSureMessageDelete}
                                    deleteCertificate={() => this.deleteAttachedCertificate(uc[0])}/>

            </ButtonToolbar></td>
        </tr>)
    }
}

export default Certificate;