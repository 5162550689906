import React from "react";
import {Col, Form, Row} from "react-bootstrap";
import Select from 'react-select';
// import Select from "react-dropdown-select";
import {strings} from "../Localization/Localization";

const EditCourse = (props) => {
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderBottom: '3px #0085EF',
            color: state.isSelected ? 'yellow' : 'black',
            backgroundColor: state.isSelected ? '#0085EF' : 'white',
        }),
        control: (provided) => ({
            ...provided,
            borderRadius: "20px"
        })
    }

    return (
        <Row>
            <Col>
                <Form>
                    <Form.Group controlId="name">
                        <Form.Label>{strings.name}</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            defaultValue={props.entity.name}
                            onChange={props.handleChange}/>
                    </Form.Group>
                    <Form.Group controlId="link">
                        <Form.Label>{strings.courseLink}</Form.Label>
                        <Form.Control
                            type="text"
                            name="link"
                            defaultValue={props.entity.link}
                            onChange={props.handleChange}/>
                    </Form.Group>
                    <Form.Group controlId="description">
                        <Form.Label>{strings.courseDescription}</Form.Label>
                        <Form.Control
                            type="text"
                            name="description"
                            defaultValue={props.entity.description}
                            onChange={props.handleChange}/>
                    </Form.Group>
                    <Form.Group controlId="status">
                        <Form.Label className="mr-2">{strings.courseStatus} </Form.Label>
                        <select name="status" value={props.entity.status} className="form-control custom-select"
                                onChange={props.handleChange}>
                            <option value="true">{strings.courseStatusOptionActive}</option>
                            <option value="false">{strings.courseStatusOptionInactive}</option>
                        </select>
                    </Form.Group>
                    <Form.Group controlId="userUpload">
                        <Form.Label className="mr-2">{strings.courseCertificate}</Form.Label>
                        <select name="userUpload" value={props.entity.userUpload} onChange={props.handleChange}
                                className="form-control custom-select">
                            <option value="true">{strings.courseCertificateAddCanUpload}</option>
                            <option value="false">{strings.courseCertificateAddCannotUpload}</option>
                        </select>
                    </Form.Group>
                    <Form.Group controlId="evaluation">
                        <Form.Label className="mr-2">{strings.courseEvaluation}</Form.Label>
                        <select required={true} name="evaluation" className="form-control custom-select"
                                value={props.entity.evaluation} onChange={props.handleChange}>
                            <option>{strings.courseEvaluationType}</option>
                            <option value="PRE">{strings.evaluationPre}</option>
                            <option value="POST">{strings.evaluationPost}</option>
                            <option value="BOTH">{strings.evaluationBoth}</option>
                            <option value="NOTHING">{strings.evaluationNothing}</option>
                        </select>
                        <Form.Group className="form-group"></Form.Group>
                    </Form.Group>
                    <Form.Group controlId="evaluation">
                        {props.entity?.evaluation === "PRE" ?
                            <div><Form.Label>{strings.coursePreEvaluation}</Form.Label><input name="evaluationLinkPre" onChange={props.handleChange}
                                                                     type="evaluation" value={props.entity.evaluationLinkPre}
                                                                               className="form-control"/>
                            </div> : null}
                        {props.entity?.evaluation === "POST" ?
                            <div><Form.Label>{strings.coursePostEvaluation}</Form.Label><input name="evaluationLinkPost" onChange={props.handleChange}
                                                                      type="evaluation" value={props.entity.evaluationLinkPost}
                                                                                className="form-control"/>
                            </div> : null}
                        {props.entity?.evaluation === "BOTH" ? <div>
                            <Form.Label>{strings.coursePreEvaluation}</Form.Label>
                            <input name="evaluationLinkPre" onChange={props.handleChange} type="evaluation"
                                   value={props.entity.evaluationLinkPre}
                                   className="form-control"/>
                            <br/>
                            <Form.Label>{strings.coursePostEvaluation}</Form.Label>
                            <input name="evaluationLinkPost" onChange={props.handleChange} type="evaluation"
                                   value={props.entity.evaluationLinkPost}
                                   className="form-control"/>
                        </div> : null}
                    </Form.Group>
                    <Form.Group controlId="points">
                        <Form.Label>{strings.coursePoints}</Form.Label>
                        <Form.Control
                            type="number"
                            name="points"
                            required
                            defaultValue={props.entity.points}
                            onChange={props.handleChange}/>
                    </Form.Group>
                    <Form.Group controlId="sortNo">
                        <Form.Label>{strings.courseSortNo}</Form.Label>
                        <Form.Control
                            type="number"
                            name="sortNo"
                            required
                            defaultValue={props.entity.sortNo}
                            onChange={props.handleChange}/>
                    </Form.Group>
                    <Form.Group controlId="language">
                        <Form.Label className="mr-2">{strings.courseLanguage} </Form.Label>
                        <select name="language" defaultValue={props.entity.language} onChange={props.handleChange}
                                className="form-control custom-select">
                            <option>{strings.courseLanguageChoose}</option>
                            <option>{strings.courseLanguageMk}</option>
                            <option>{strings.courseLanguageAl}</option>
                            <option>{strings.courseLanguageEn}</option>
                        </select>
                    </Form.Group>
                    {/*<Form.Group controlId="category">*/}
                    {/*    <Form.Label>Категорија</Form.Label>*/}
                    {/*    <Form.Control*/}
                    {/*        type="category"*/}
                    {/*        name="categoryNameMk"*/}
                    {/*        required={true}*/}
                    {/*        defaultValue={props.entity.category?.nameMk}*/}
                    {/*        onChange={props.handleChange}/>*/}
                    {/*</Form.Group>*/}
                    <Form.Group controlId="category">
                        <Form.Label>{strings.courseCategory}</Form.Label>
                        <Select
                            styles = { customStyles }
                            // placeholder={'Одберете категорија'}
                            closeMenuOnSelect={true}
                            // name="categoryNameMk"
                            isSearchable={true}
                            placeholder={props.entity.category?.nameMk}
                            required={false}
                            // components={{ IndicatorSeparator:() => null }}
                            // maxMenuHeight={200}
                            options={
                                props.entity.categories?.map((item) => (
                                    {value:item.id,label:item.nameMk}
                                ))}
                            // defaultValue={props.entity.category?.nameMk}
                            onChange={props.handleCategoryChange}
                            // value={props.entity.category}
                        />
                    </Form.Group>

                    {/*<Form.Group controlId="date">*/}
                    {/*    <Form.Label>Датум од</Form.Label>*/}
                    {/*    <Form.Control*/}
                    {/*        type="date"*/}
                    {/*        name="dateFrom"*/}
                    {/*        required*/}
                    {/*        defaultValue={props.entity.dateFrom}*/}
                    {/*        onChange={props.handleChange}/>*/}
                    {/*</Form.Group>*/}
                    {/*<Form.Group controlId="date">*/}
                    {/*    <Form.Label>Датум до</Form.Label>*/}
                    {/*    <Form.Control*/}
                    {/*        type="date"*/}
                    {/*        name="dateTo"*/}
                    {/*        required*/}
                    {/*        defaultValue={props.entity.dateTo}*/}
                    {/*        onChange={props.handleChange}/>*/}
                    {/*</Form.Group>*/}
                    {/*{props.dateValid ? 'Валидни' : 'Внесете соодветен датум'}*/}
                    <Form.Group controlId="dateFrom">
                                <Form.Label>{strings.dateFrom}</Form.Label>
                                <Form.Control required={false} type="date" name="dateFrom"
                                       defaultValue={props.entity.dateFrom}
                                       onChange={props.handleDateFromChange}/>
                    </Form.Group>
                    <Form.Group controlId="dateTo">
                                <Form.Label>{strings.dateTo}</Form.Label>
                                <Form.Control required={false} type="date" name="dateTo"
                                       defaultValue={props.entity.dateTo}
                                       onChange={props.handleDateToChange}/>
                    </Form.Group>

                    {/*{props.imageValid ? 'Валидно' : 'Внесете соодветна слика'}*/}
                    <Form.Group controlId="image">
                        <Form.Label>{strings.courseImage}</Form.Label><br/>
                        <input type="file" className="form-control" name="image"  accept=".jpg, .jpeg, .png"
                               onChange={props.imageUploadHandler}/>
                        <Form.Label style={{paddingTop:"5px"}}>{strings.currentImageName} {props.entity.imageName ? props.entity.imageName : strings.courseNoImage }</Form.Label><br/>
                        {/*<Form.Label style={{fontWeight:"bold"}}>{strings.allowedImageFormats}</Form.Label>*/}
                        {/*{props.image.imageValid? null:<div className="alert alert-danger" role="alert"> Внесете соодветна слика</div>}*/}
                    </Form.Group>
                    <Form.Group controlId="template">
                        <Form.Label>{strings.template}</Form.Label><br/>
                        <input type="file" className="form-control" name="template" accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                               onChange={props.templateUploadHandler}/>
                        {/*<Form.Label style={{fontWeight:"bold", margin: "0"}}>{strings.allowedTemplateFormat}</Form.Label><br/>*/}
                        <Form.Label style={{paddingTop:"5px"}}>{strings.currentTemplate} {props.entity.templateName ? props.entity.templateName : strings.courseNoTemplate }</Form.Label>
                        {/*{props.image.imageValid? null:<div className="alert alert-danger" role="alert"> Внесете соодветна слика</div>}*/}
                    </Form.Group>
                </Form>
            </Col>
        </Row>
    )
}

export default EditCourse;
