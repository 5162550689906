import React, { Component } from "react";
import "../../assets/css/theme/base.css";
import "./Home.css";
import coursesRepository from "../../repository/CoursesRepository";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddUserCourseModal from "./AddUserCourseModal";
import UserCourseRepository from "../../repository/UserCourseRepository";
import { Card } from "react-bootstrap";
import searchIcon from "../../assets/images/search.svg";
import img from "./../../assets/images/Slika_Kurs.png";
import "./CourseCard.css";
import Nav from "../Navbar/Nav";
import dostapniobuki from "./../../assets/images/dashboard_icons/dostapniobuki.png";
import { components } from "react-select";
import { default as ReactSelect } from "react-select";
import categoryRepository from "../../repository/CategoryRepository";
import dateFormat from "dateformat";
import styled from "styled-components";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { strings } from "../Localization/Localization";
import ReactPaginate from "react-paginate";
import Typography from "@material-ui/core/Typography";
import { Popper } from "@mui/material";
import { Paper } from "@mui/material";
import { Box } from "@mui/material";
import Loading from "../loading/Loader";
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    minWidth: 250,
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: "black",
      },
    },
  },
}));
const StyledMenuDate = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    minWidth: 180,
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: "black",
      },
    },
  },
}));
const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <div style={{ display: "flex", justifyContent: "start" }}>
          <input
            className="mt-1"
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />
          <label className="ml-2">{props.label}</label>
          {"  "}
        </div>
      </components.Option>
    </div>
  );
};
const colourStyles = {
  control: (base, state) => ({
    ...base,
    borderRadius: "10px",
    textTransform: "uppercase",
    background: state.isFocused ? "#0061AF" : "#0061AF",
    minWidth: "250px",
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "white",
    display: "flex",
    fontSize: "15px",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    svg: {
      fill: "white",
    },
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected ? "#D6EDFF" : "white",
    color: state.isSelected ? "black" : "black",
    "&:hover": {
      background: state.isSelected ? "#D6EDFF" : "#F9F9F9",
    },
  }),
  indicatorSeparator: (base, state) => ({
    ...base,
    display: "none",
  }),
};
class Home extends Component {
  state = {
    courses: [],
    categories: [],
    anchorEl: null,
    anchorElFromDate: null,
    anchorElToDate: null,
    anchorElTitle: null,
    anchorElDesc: null,
    optionSelected: null,
    description: null,
    title: null,
    optionSelectedLanguage: null,
    startDate: null,
    endDate: null,
    loading: false,
    availableCoursesFilterDto: {
      searchString: null,
      language: null,
      categoryIds: null,
      dateFrom: null,
      dateTo: null,
    },
  };
  componentDidMount() {
    this.getCourses();
    this.getCategories();
  }
  getCourses = (page = 0, size = 12) => {
    let email = localStorage.getItem("userEmail");
    this.setState({
      loading: true,
    });
    coursesRepository
      .getActiveCourses(email, page, size, this.state.availableCoursesFilterDto)
      .then((res) => {
        console.log(res.data);
        this.setState({
          loading: false,
        });
        this.setState({
          courses: res.data.content,
          pageCount: res.data.totalPages,
        });
      })
      .catch(() => {
        // toast.error('Неуспешно')
      });
  };
  getCategories = () => {
    categoryRepository.getAllCategories().then((res) => {
      this.setState({
        categories: res.data,
      });
    });
  };
  add = (courseId) => {
    let email = localStorage.getItem("userEmail");
    return UserCourseRepository.addUserCourse(email, courseId)
      .then(() => {
        toast.success(`${strings.successfullyAddedCourse}`);
        this.getCourses();
      })
      .catch(() => {
        toast.error(() => {
          toast.error(`${strings.unsuccessfullyAddedCourse}`);
        });
      });
  };
  handleChange = (selected) => {
    let listCatIds = [];
    this.setState({
      optionSelected: selected,
    });
    for (var i = 0; i < selected.length; i++) {
      listCatIds.push(selected[i].value);
    }
    this.setState(
      {
        availableCoursesFilterDto: {
          ...this.state.availableCoursesFilterDto,
          categoryIds: listCatIds,
        },
      },
      () => {
        this.getCourses();
      }
    );
    if (listCatIds.length === 0) {
      this.setState(
        {
          availableCoursesFilterDto: {
            ...this.state.availableCoursesFilterDto,
            categoryIds: null,
          },
        },
        () => {
          this.getCourses();
        }
      );
    }
  };
  handleChangeLanguages = (selected) => {
    this.setState({
      optionSelectedLanguage: selected,
    });
    if (
      this.state.optionSelectedLanguage !== null &&
      selected.value === this.state.optionSelectedLanguage.value
    ) {
      this.setState({
        optionSelectedLanguage: null,
      });
      this.setState(
        {
          availableCoursesFilterDto: {
            ...this.state.availableCoursesFilterDto,
            language: null,
          },
        },
        () => {
          this.getCourses();
        }
      );
    } else {
      this.setState(
        {
          availableCoursesFilterDto: {
            ...this.state.availableCoursesFilterDto,
            language: selected.value,
          },
        },
        () => {
          this.getCourses();
        }
      );
    }
  };
  handleDateChangeRaw = (e) => {
    e.preventDefault();
  };
  handleChangeSearchString = (event) => {
    if (event.key === "Enter") {
      this.setState(
        {
          availableCoursesFilterDto: {
            ...this.state.availableCoursesFilterDto,
            searchString: event.target.value,
          },
        },
        () => {
          this.getCourses();
        }
      );
    }
    if (event.target.value === "") {
      this.setState({
        availableCoursesFilterDto: {
          ...this.state.availableCoursesFilterDto,
          searchString: null,
        },
      });
    }
  };
  handleChangeSearch = (event) => {
    this.setState({
      availableCoursesFilterDto: {
        ...this.state.availableCoursesFilterDto,
        searchString: event.target.value,
      },
    });
    if (event.target.value === "") {
      this.setState(
        {
          availableCoursesFilterDto: {
            ...this.state.availableCoursesFilterDto,
            searchString: null,
          },
        },
        () => this.getCourses()
      );
    }
  };
  clearSearchString = () => {
    this.setState(
      {
        availableCoursesFilterDto: {
          ...this.state.availableCoursesFilterDto,
          searchString: null,
        },
      },
      () => this.getCourses()
    );
    document.getElementById("searchBar").value = "";
  };

  handleChangeDateFrom = (date) => {
    if (
      dateFormat(date, "yyyy-mm-dd") >
      this.state.availableCoursesFilterDto.dateTo
    ) {
      this.setState({
        startDate: null,
      });
      return toast.error(strings.validDate);
    } else {
      this.setState({
        startDate: date,
      });
      this.setState(
        {
          availableCoursesFilterDto: {
            ...this.state.availableCoursesFilterDto,
            dateFrom: dateFormat(date, "yyyy-mm-dd"),
          },
        },
        () => {
          this.getCourses();
        }
      );
    }
  };
  handleChangeDateTo = (date) => {
    console.log(date);
    if (
      this.state.availableCoursesFilterDto.dateFrom >
      dateFormat(date, "yyyy-mm-dd")
    ) {
      this.setState({
        endDate: null,
      });
      return toast.error(strings.validDate);
    } else {
      this.setState({
        endDate: date,
      });
      this.setState(
        {
          availableCoursesFilterDto: {
            ...this.state.availableCoursesFilterDto,
            dateTo: dateFormat(date, "yyyy-mm-dd"),
          },
        },
        () => {
          this.getCourses();
        }
      );
    }
  };
  showCategory = (item) => {
    if (item.language === "Македонски") {
      return item.category.nameMk;
    } else if (item.language === "Shqip") {
      return item.category.nameAl;
    } else if (item.language === "English") {
      return item.category.nameEn;
    }
  };
  category = (item) => {
    if (item.language === "Македонски") {
      return "Категорија";
    } else if (item.language === "Shqip") {
      return "Kategoria";
    } else if (item.language === "English") {
      return "Category";
    }
  };
  dateOfCourse = (item) => {
    if ([item.dateFrom, item.dateTo].filter(Boolean).join(" - ")) {
      return [item.dateFrom, item.dateTo].filter(Boolean).join(" - ");
    } else {
      if (item.language === "Македонски") {
        return "Нема датум";
      } else if (item.language === "Shqip") {
        return "Nuk ka datë";
      } else if (item.language === "English") {
        return "No date";
      }
    }
  };
  clearStateOfDateFrom = () => {
    this.setState(
      {
        startDate: null,
        availableCoursesFilterDto: {
          ...this.state.availableCoursesFilterDto,
          dateFrom: null,
        },
      },
      () => {
        this.getCourses();
      }
    );
  };
  clearStateOfDateTo = () => {
    this.setState(
      {
        endDate: null,
        availableCoursesFilterDto: {
          ...this.state.availableCoursesFilterDto,
          dateTo: null,
        },
      },
      () => {
        this.getCourses();
      }
    );
  };
  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };
  handleClose2 = () => {
    this.setState({
      anchorEl: null,
    });
  };
  handleClickFromDate = (event) => {
    this.setState({
      anchorElFromDate: event.currentTarget,
    });
  };
  handleCloseFromDate = () => {
    this.setState({
      anchorElFromDate: null,
    });
  };
  handlePageClick = (data) => {
    this.getCourses(data.selected);
  };
  handleClickToDate = (event) => {
    this.setState({
      anchorElToDate: event.currentTarget,
    });
  };
  handleCloseToDate = () => {
    this.setState({
      anchorElToDate: null,
    });
  };
  truncateTitle = (str) => {
    return str.length > 35 ? str.substring(0, 35) + "..." : str;
  };
  truncateDescription = (str) => {
    return str.length > 90 ? str.substring(0, 90) + "..." : str;
  };
  handlePopoverOpenTitle = (event) => {
    this.setState({
      anchorElTitle: event.currentTarget,
    });
    if (!event.currentTarget.innerText.includes("...")) {
      this.setState({
        anchorElTitle: null,
      });
    }
  };

  handlePopoverCloseTitle = () => {
    this.setState({
      anchorElTitle: null,
    });
  };
  handlePopoverOpenDescription = (event) => {
    this.setState({
      anchorElDesc: event.currentTarget,
    });
    if (!event.currentTarget.innerText.includes("...")) {
      this.setState({
        anchorElDesc: null,
      });
    }
  };

  handlePopoverCloseDescription = () => {
    this.setState({
      anchorElDesc: null,
    });
  };
  setTitle = (item) => {
    this.setState({
      title: item.courseName,
    });
  };
  setDescription = (item) => {
    this.setState({
      description: item.courseDescription,
    });
  };
  render() {
    const options = this.state.categories.map((cat) => ({
      value: cat.id,
      label:
        localStorage.getItem("activeLanguage") === "mk"
          ? cat.nameMk
          : localStorage.getItem("activeLanguage") === "al"
          ? cat.nameAl
          : localStorage.getItem("activeLanguage") === "en"
          ? cat.nameEn
          : null,
    }));
    const optionsLanguages = [
      { value: "Македонски", label: `${strings.macedonian}` },
      { value: "Shqip", label: `${strings.albanian}` },
      { value: "English", label: `${strings.english}` },
    ];
    return (
      <>
        <Nav
          title={
            <div>
              {strings.available}
              <br /> {strings.coursesHeader1}
            </div>
          }
          image={dostapniobuki}
        />
        <div className="container pb-5">
          <p
            className="ml-lg-3 mt-lg-2"
            style={{ color: "black", fontSize: "20px", fontWeight: "500" }}
          >
            {strings.filterBy}
          </p>
          <div
            className="filterDiv pb-3"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <ReactSelect
              placeholder={strings.languageOfCourse}
              className="mt-1 ml-xl-3"
              isMulti={false}
              options={optionsLanguages}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              isSearchable={false}
              styles={colourStyles}
              controlShouldRenderValue={false}
              isClearable={false}
              value={this.state.optionSelectedLanguage}
              components={{
                Option,
              }}
              onChange={this.handleChangeLanguages}
              allowSelectAll={true}
            />
            <ReactSelect
              id="categorySelect"
              placeholder={strings.category}
              className="mt-1"
              options={options}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              isSearchable={false}
              styles={colourStyles}
              controlShouldRenderValue={false}
              isClearable={false}
              components={{
                Option,
              }}
              onChange={this.handleChange}
              allowSelectAll={true}
              value={this.state.optionSelected}
            />
            <div>
              <Button
                className="mt-1"
                id="dateFromToBtn"
                disableRipple
                aria-controls={
                  Boolean(this.state.anchorEl)
                    ? "demo-customized-menu"
                    : undefined
                }
                aria-haspopup="true"
                aria-expanded={
                  Boolean(this.state.anchorEl) ? "true" : undefined
                }
                style={{
                  background: "#0061AF",
                  color: "white",
                  minWidth: "250px",
                  borderRadius: "10px",
                  position: "relative",
                  left: "2px",
                }}
                onClick={this.handleClick}
                endIcon={
                  <KeyboardArrowDownIcon style={{ fontSize: "1.6rem" }} />
                }
              >
                {strings.dateCourse}
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={this.state.anchorEl}
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleClose2}
              >
                {this.state.startDate !== null ? (
                  <MenuItem onClick={this.clearStateOfDateFrom} disableRipple>
                    {this.state.availableCoursesFilterDto.dateFrom}
                  </MenuItem>
                ) : (
                  <MenuItem
                    id="demo-customized-button"
                    aria-controls={
                      Boolean(this.state.anchorElFromDate)
                        ? "demo-customized-menu"
                        : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={
                      Boolean(this.state.anchorElFromDate) ? "true" : undefined
                    }
                    
                    onClick={this.handleClickFromDate}
                    disableRipple
                  >
                    {strings.fromDate}
                  </MenuItem>
                )}
                {this.state.endDate !== null ? (
                  <MenuItem onClick={this.clearStateOfDateTo}>
                    {this.state.availableCoursesFilterDto.dateTo}
                  </MenuItem>
                ) : (
                  <MenuItem
                    id="demo-customized-button"
                    aria-controls={
                      Boolean(this.state.anchorElToDate)
                        ? "demo-customized-menu"
                        : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={
                      Boolean(this.state.anchorElToDate) ? "true" : undefined
                    }
                    
                    onClick={this.handleClickToDate}
                    disableRipple
                  >
                    {strings.toDate}
                  </MenuItem>
                )}
              </StyledMenu>
              <StyledMenuDate
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={this.state.anchorElFromDate}
                open={Boolean(this.state.anchorElFromDate)}
                onClose={this.handleCloseFromDate}
              >
                <MenuItem>
                  <Calendar
                    onChange={this.handleChangeDateFrom}
                    name="dateFrom"
                    value={this.state.startDate}
                  />
                </MenuItem>
              </StyledMenuDate>
              <StyledMenuDate
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={this.state.anchorElToDate}
                open={Boolean(this.state.anchorElToDate)}
                onClose={this.handleCloseToDate}
              >
                <MenuItem>
                  <Calendar
                    onChange={this.handleChangeDateTo}
                    name="dateTo"
                    value={this.state.endDate}
                  />
                </MenuItem>
              </StyledMenuDate>
            </div>
            <br class="d-md-none" />
            <div className="p-1 searchDiv mr-lg-1 mt-lg-1">
              {/* <img
                style={{position: "relative", left: "30px"}}
                src={searchIcon}
                onClick={()=>this.getCourses()}
              /> */}
              <div style={{ position: "relative" }}>
                <input
                  id="searchBar"
                  style={{
                    border: "1px solid lightgrey",
                    height: "30px",
                    borderRadius: "10px",
                    fontSize: "14px",
                    minWidth: "220px",
                  }}
                  onChange={this.handleChangeSearch}
                  onKeyPress={this.handleChangeSearchString}
                  placeholder={strings.search}
                  type="text"
                />
                {this.state.availableCoursesFilterDto.searchString !== null && (
                  <i
                    style={{
                      webkitTextStroke: "0.5px white",
                      cursor: "pointer",
                      position: "absolute",
                      verticalAlign: "middle",
                      right: "10px",
                      top: 0,
                      height: "100%",
                    }}
                    class="fa fa-times mt-2 ml-1"
                    aria-hidden="true"
                    onClick={this.clearSearchString}
                  ></i>
                )}
              </div>
              <i
                class="fa fa-search ml-2"
                aria-hidden="true"
                style={{ fontSize: "30px", cursor: "pointer" }}
                onClick={() => this.getCourses()}
              ></i>
            </div>
          </div>
          {!this.state.loading ? (
            <div className="coursesContainer row">
              {this.state.courses.map((item) => {
                return (
                  <div className="col-lg-3 d-flex align-items-stretch pt-3">
                    <Card
                      style={{ width: "100%" }}
                      className="box shadow"
                      id={`card-${item.id}`}
                    >
                      <div>
                        {item.imageContent === null ? (
                          <Card.Img
                            variant="top"
                            src={img}
                            style={{ height: "160px" }}
                          />
                        ) : (
                          <Card.Img
                            variant="top"
                            src={`data:${item.imageType};base64,${item.imageContent}`}
                            style={{ height: "160px" }}
                          />
                        )}
                      </div>
                      <Card.Body
                        style={{
                          overflow: "hidden",
                          paddingRight: "5px",
                          paddingLeft: "5px",
                        }}
                      >
                        <div>
                          <Card.Title
                            id="courseTitle"
                            style={{ height: "50px" }}
                            onMouseOver={() => this.setTitle(item)}
                            onMouseEnter={this.handlePopoverOpenTitle}
                            onMouseLeave={this.handlePopoverCloseTitle}
                          >
                            {this.truncateTitle(item.courseName)}
                          </Card.Title>
                          <Box>
                            <Popper
                              open={Boolean(this.state.anchorElTitle)}
                              anchorEl={this.state.anchorElTitle}
                              disablePortal
                              onClose={this.handlePopoverCloseTitle}
                              style={{ pointerEvents: "none" }}
                            >
                              <Paper
                                style={{
                                  boxShadow:
                                    "0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.05)",
                                }}
                              >
                                <Typography sx={{ p: 2 }}>
                                  {this.state.title}
                                </Typography>
                              </Paper>
                            </Popper>
                          </Box>

                          <Card.Subtitle
                            className="mb-2"
                            id="courseDescription"
                            onMouseOver={() => this.setDescription(item)}
                            onMouseEnter={this.handlePopoverOpenDescription}
                            onMouseLeave={this.handlePopoverCloseDescription}
                            style={{ overflow: "hidden", height: "100px" }}
                          >
                            {" "}
                            {this.truncateDescription(item.courseDescription)}
                          </Card.Subtitle>
                          <Box>
                            <Popper
                              open={Boolean(this.state.anchorElDesc)}
                              anchorEl={this.state.anchorElDesc}
                              disablePortal
                              onClose={this.handlePopoverCloseDescription}
                              style={{ pointerEvents: "none" }}
                            >
                              <Paper
                                style={{
                                  boxShadow:
                                    "0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.12)",
                                }}
                              >
                                <Typography sx={{ p: 2 }}>
                                  {this.state.description}
                                </Typography>
                              </Paper>
                            </Popper>
                          </Box>
                        </div>
                        <br />
                        <div>
                          <Card.Text
                            id="courseCategory"
                            style={{ height: "100px" }}
                          >
                            <p style={{ fontWeight: "bold" }}>
                              {this.category(item)}:{" "}
                              {"★" + item.category
                                ? this.showCategory(item)
                                : "/"}
                            </p>
                          </Card.Text>
                          <div
                            style={{
                              display: "flex",
                              fontSize: "10px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <Card.Text>
                              {"📅" + " " + this.dateOfCourse(item)}
                            </Card.Text>
                            <Card.Text className="pl-3">
                              {"🌐" + " " + item.language}
                            </Card.Text>
                          </div>
                        </div>
                      </Card.Body>
                      <div id="prijaviSeDiv" style={{ display: "flex" }}>
                        <AddUserCourseModal
                          btnClass={"ml-1"}
                          prompt={strings.signUpModalQuestion}
                          addUserCourse={() => this.add(item.courseId)}
                        />
                        {/*When we click Пријави се modal shows up*/}
                      </div>
                    </Card>
                    {/*  */}
                  </div>
                );
              })}
              {/*  */}
            </div>
          ) : (
            <Loading />
          )}
          <br />

          <div className="ml-3 mt-3">
            <ReactPaginate
              previousLabel={strings.previous}
              nextLabel={strings.next}
              breakLabel={"..."}
              pageCount={this.state.pageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={this.handlePageClick}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </>
    );
  }
}

export default Home;
