import React, {Component} from "react";
import UserCourseRepository from "../../repository/UserCourseRepository";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import './css/AddAttachment.css';
import downloadIcon from '../../assets/images/downloadIcon.svg'
import FileSaver from 'file-saver';
import Nav from "../Navbar/Nav";

class UserUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: null,
            attachment: {}
        }
    }

    componentDidMount() {
        this.getUserAttachment();
    }

    onChangeHandler = (event) => {
        this.setState({file: event.target.files[0]})
    };
    getUserAttachment = () => {
        let email = localStorage.getItem('userEmail');
        let courseId = this.props.match.params.id;
        UserCourseRepository.getUserAtt(email, courseId).then(res => {
          this.setState({attachment: res.data})
        });
    }
    uploadFile = () => {
        let email = localStorage.getItem('userEmail');
        let courseId = this.props.match.params.id;
        const form = new FormData();
        form.append("email", email);
        form.append("courseId", courseId);
        form.append("attachment", this.state.file);

        UserCourseRepository.addAttachment1(form).then(() => {
            toast.success("Успешно прикачен сертификат");
            this.props.history.push("/mycourses")
        }).catch(() => {
            toast.error("Неуспешно прикачување на сертификат")
        });
    };
    downloadCertificate=()=>{
        let email = localStorage.getItem('userEmail');
        let courseId = this.props.match.params.id;
      UserCourseRepository.downloadCertificate(email, courseId).then(response=>{
        var blob=new Blob([response.data],{type:response.data.type});
        FileSaver.saveAs(blob, this.state.attachment.name)
    })
   
    }

    render() {
        return (
            <>
            <Nav/>
            <div className="container">
                <div className="row mt-4">
                    <div className=" col-md-4">

                        <label style={{color:"black", fontSize:"14px", marginLeft:"-10px"}}>Прикачете го вашиот сертификат тука</label>

                        <div className="row custom-file mt-3 mb-3">
                            <input onChange={this.onChangeHandler} type="file" className="custom-file-input"
                                   id="signedFileUploadInput" name="file"/>
                            <label className="custom-file-label"
                                   htmlFor="customFile">{this.state.file !== null ? this.state.file.name : ""}</label>
                        </div>
                    </div>
                    <div className="offset-md-0 col-md-4">
                        <div className="row custom-file mt-3 mb-3">
                            <button type="button" className="btn btn-sm" id="margBottom"
                                    onClick={this.uploadFile}>Прикачи
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <table className="table">
                        <thead>
                        <tr className="rowCertificate">
                            <th className="colCertificate" style={{width:"400px"}}>Име</th>
                            <th className="colCertificate">Формат</th>
                            <th className="colCertificate">Големина</th>
                            <th className="downloadCertificate">Превземи</th>
                        </tr>
                        </thead>
                        <br />
                        <tbody>
                        <tr>
                            <td>{this.state.attachment.name}</td>
                            <td>{this.state.attachment.mimeType}</td>
                            <td>{this.state.attachment.size}</td>
                            <td>{this.state.attachment.length!==0 && <button onClick={this.downloadCertificate} id="downloadC" style={{border:"none", background:"white"}}><img src={downloadIcon}/></button>}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            </>
        )
    }
}

export default UserUpload;
