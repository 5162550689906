import React, {Component} from "react";
import UserRepository from "../../repository/UserRepository";
import {toast} from "react-toastify";
import ConfirmMailSendModal from "./ConfirmMailSendModal";
import "./Statistics.css";
import {strings} from "../Localization/Localization";


class Statistics extends Component {

    state = {
        users: {},
        workingPosition:'',
        messageTitle:'',
        message:''
    };

    componentDidMount() {
        this.getUsers();
    }

    getUsers = () => {
        UserRepository.getUsersStatistics().then(res => {
            this.setState({users: res.data})
        }).catch((err) => {
            console.log(err)
        })
    }

    workingPositionHandler=(e)=>{
        this.setState({
            workingPosition: e.target.value
        });
    };

    titleChangeHandler=(e)=>{
        this.setState({
            messageTitle: e.target.value
        });
    };

    messageChangeHandler=(e)=>{
        this.setState({
            message: e.target.value
        });
    };



    mailToGroup=()=>{
        document.getElementById("messageForm").reset();
        if(this.state.workingPosition!=='') {
            return UserRepository.bulkMail(this.state.workingPosition,this.state.messageTitle,this.state.message).then(() => {
                toast.success(strings.toastReminderSuccess)
            }).catch(() => {
                toast.error(strings.toastReminderFail)
            })
        }else{
            return UserRepository.bulkMail(this.state.workingPosition).then(() => {
                toast.error(strings.chooseWorkGroup)
            }).catch(() => {
                toast.error(strings.chooseWorkGroup)
            })

        }
    };


    render() {
        return (
            <div>
                <div className="container">
                    <div className="row" style={{paddingTop:"10px"}}><h3>{strings.statisticsRegisteredUsers}</h3></div>
                    <form id="messageForm">
                    <div className="row">
                        <ol>
                            <li>{strings.statisticsMedicalDoctors} : {this.state.users.doktori}</li>
                            <li>{strings.statisticsDentalDoctors}: {this.state.users.stomatolog}</li>
                            <li>{strings.statisticsNurses}: {this.state.users.sestra}</li>
                            <li>{strings.statisticsHealthColaborators}: {this.state.users.sorabotnik}</li>
                            <li>{strings.statisticsOther}: {this.state.users.drugo}</li>
                        </ol>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h4>{strings.statisticsReminder}: </h4>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-7">
                                    <div className="form-group">
                                        <input className="form-control" type="text" placeholder={strings.title}
                                               required
                                               onChange={(e) => this.titleChangeHandler(e)}
                                               name="title" autoComplete="off"/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-7">
                                    <div className="form-group">
                                        <textarea className="form-control" type="text" placeholder={strings.message}
                                                  required
                                                  onChange={(e) => this.messageChangeHandler(e)}
                                                  name="message" autoComplete="off"
                                                  rows="4"
                                                  cols="50"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <select className="form-control custom-select" type="dropdown"
                                                style={{paddingBottom: 10}} required
                                                onChange={(e) => this.workingPositionHandler(e)}
                                                name="employedAs" autoComplete="off">
                                            <option value="">{strings.jobPosition}
                                            </option>
                                            <option value="Доктор"
                                            >{strings.statisticsMedicalDoctors}
                                            </option>
                                            <option value="Стоматолог"
                                            >{strings.statisticsDentalDoctors}
                                            </option>
                                            <option value="Сестра"
                                            >{strings.statisticsNurses}
                                            </option>
                                            <option value="Соработник"
                                            >{strings.statisticsHealthColaborators}
                                            </option>
                                            <option value="Друго"
                                            >{strings.statisticsOther}
                                            </option>

                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-8">
                                    <ConfirmMailSendModal btnClass={"ml-1 mt-2 roundEdge"}
                                                          prompt={strings.sendReminderConfirmation}
                                                          mailToGroup={this.mailToGroup}/>
                                </div>
                            </div>
                        </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

}

export default Statistics;