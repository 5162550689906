import React, {Component} from 'react';
import '../../assets/css/theme/login-6.css'
import Background from '../../assets/images/Pozadina-Login-1.png'
import MailService from "../../repository/service/MailService";
import {Link} from "react-router-dom";
import './ResetPassword.css';
import { strings } from '../Localization/Localization';

class ResetPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: ""
        }
    }

    sendRequest = (e) => {
        debugger;
        let email = this.state.email;
        MailService.sendRequest(email).then(resp => {
            alert(`${strings.successfullResetPass}`);
            this.props.history.push("/login");
        }).catch(error => {
            alert(`${strings.resetPasswordValidation}`);
        });
    };

    updateInput = (e) => {
        e.preventDefault();
        this.setState({
            "email": e.target.value
        });
    };

    goBackToLogin=(path)=>{
        this.props.history.push(path);
    };

    render() {
        return (
            <div className="kt-grid kt-grid--ver kt-grid--root kt-page kt-grid-page">
                <div className="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v6 kt-login--signin"
                     id="kt_login">
                    <div
                        className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
                        <div
                            className="kt-grid__item  kt-grid__item--order-tablet-and-mobile-2  kt-grid kt-grid--hor kt-login__aside">
                            <div className="kt-login__wrapper">
                                <div className="kt-login__container">
                                    <div className="kt-login__body">
                                        <div className="kt-login__logo_mz" id="mz_logo">
                                            <a href="!#">
                                                <img src={require('../../assets/images/MZ.png')}
                                                     className={"img-responsive p-0"} style={{width: "105%"}} alt=""/>
                                            </a>
                                        </div>


                                        <div className="kt-login__signin container box-shadow" style={{borderRadius:"10px",
                                            padding:"20px", marginBottom:"50px"}}>
                                            <div className="kt-login__logo">
                                                <a href="!#">
                                                    <img src={require('../../assets/images/cropped-cropped-e-health.jpg')}
                                                         className={"img-responsive"} style={{width: "60%"}} alt=""/>
                                                    {/*<img src={require('../../assets/images/Who-Europe_logo-1.jpg')}*/}
                                                    {/*     className={"img-responsive"} style={{width: "50%"}} alt=""/>*/}

                                                </a>
                                            </div>
                                            <div className="kt-login__head">
                                                <h3 className="kt-login__title row-cols-2">
                                                    <div id="reset_pw">{strings.resetPassword}</div>
                                                </h3>

                                                <div className="kt-login__desc">
                                                    <font size="2">{strings.resetPasswordText}
                                                    </font>
                                                </div>
                                            </div>
                                            <form className="kt-login__form" action="" onSubmit={this.sendRequest}>
                                                <div className="kt-form">
                                                    <div className="form-group">
                                                        <div className="kt-login__wrapper" style={{color:"black", fontWeight:"bold"}}>{strings.email}</div>
                                                        <input className="form-control" type="text" placeholder=" "
                                                               name="email" id="kt_email" onChange={this.updateInput}
                                                               autoComplete="off"/>
                                                        <div className="kt-login__extra col">
                                                            <label className="kt-checkbox">
                                                                <input type="checkbox" name="termsofuse"/> <p style={{color:"black"}}>{strings.acceptTerms}</p>
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="kt-login__actions">
                                                        <button id="kt_login_forgot_submit"
                                                                className="btn btn-brand btn-pill btn-elevate"
                                                                type="button" onClick={this.sendRequest}>{strings.send}
                                                        </button>


                                                        {/*<button type="button" id="kt_login_signin_submit"*/}
                                                        {/*        className="btn btn-brand btn-pill btn-elevate"*/}
                                                        {/*        onClick={() => this.goBackToLogin('/login')}>Назад*/}
                                                        {/*</button>*/}
                                                    </div>
                                                    <footer>
                                                        <div className="existing_account">
                                                            <font color="black">{strings.haveAccount} </font>
                                                            <Link to="/login">
                                                                <font id="font">{strings.login}</font>
                                                            </Link>
                                                        </div>
                                                        <div className="row">


                                                            <div className="col-3">

                                                            </div>

                                                            <div className="col-4">
                                                                <div className="row">
                                                                    <p style={{position:"relative",top:"128px",left:"53px",color:"gray",fontSize:"11.5px"}}>{strings.supportedBy}</p>
                                                                </div>

                                                                <div className="row">
                                                                    <img src={require('../../assets/images/usaid3.png')}
                                                                         className={"img-responsive"} style={{width: "120%" ,position:"absolute", marginTop:"95px"}} alt="usaid"/>
                                                                </div>
                                                            </div>
                                                            <div className="col-3">
                                                            </div>
                                                        </div>
                                                    </footer>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className="kt-grid__item kt-grid__item--fluid kt-grid__item--center kt-grid kt-grid--ver kt-login__content"
                            style={{backgroundImage: "url(" + Background + ")"}}>
                            <div className="kt-login__section">
                                <div className="kt-login__block">
                                    <div className="kt-login__desc">
                                        <br/>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div
                            className="kt-grid__item kt-grid__item--fluid kt-grid__item--center kt-grid kt-grid--ver kt-login__content"
                            style={{backgroundImage: "url(" + Background + ")"}}>
                            <div className="kt-login__section">
                                <div className="kt-login__block">
                                    <div className="kt-login__desc">
                                        <div>
                                            <div className="registrationTextDiv">
                                                <div className="registrationContainer">
                                                <h5>Регистрација за посета на обуки за здравствени работници</h5><br/>
                                                <p className="registrationText">
                                                    Како дел од одговорот против КОВИД-19 во Република Северна
                                                    Македонија, СЗО поддржува воведување / адаптација на специфични
                                                    модули за обука за KОВИД - 19, вклучително и обуки за спречување
                                                    пренесување на заразни агенси, правилна употреба на ЛЗО и спречување
                                                    на контаминација во болниците на постелнина, кожа и животна средина,
                                                    административни превентивни мерки, и други мерки во согласност со
                                                    упатствата на СЗО, а со цел да се намали ризикот на здравствените
                                                    работници од изложеност на КОВИД-19 и да се изградат капацитети за
                                                    управување со позитивни случаи во случај на овој, и/или следните
                                                    бранови на болеста.
                                                </p><br/>
                                                <h5>Regjistrimi për pjesëmarrje në trajnime të punonjësve
                                                    shëndetësor</h5><br/>
                                                <p className="registrationText">
                                                    Si pjesë e përgjigjes kundër KOVID-19 në Republikën e
                                                    Maqedonisë Veriore OBSH mbështet prezantimin / adaptimin e
                                                    moduleve specifike të trajnimit për KOVID-19, duke përfshirë
                                                    edhe trajnime për parandalimin e transmetimit të agjentëve
                                                    infektues, përdorimin e duhur të paisjeve personale mbrojtëse dhe
                                                    parandalimin e ndotjes në shtretërit spitalor , lëkure dhe
                                                    mjedis, masa parandaluese administrative dhe masa të tjera në
                                                    përputhje me udhëzimet e OBSH-së, në mënyrë që të
                                                    zvogëlohet rreziku I punonjësve shëndetësor nga ekspozimi ndaj
                                                    COVID-19 dhe të ndertohen kapacitete per të menaxhuar rastet
                                                    pozitive të tanishme, / ose valet e ardhëshme të sëmundjes.
                                                </p><br/>
                                                <h5>Registration for health professional training attendance </h5><br/>
                                                <p className="registrationText">
                                                    As part of the COVID-19 response in North Macedonia WHO is
                                                    supporting introduction / adaptation of specific training modules
                                                    about COVID – 19, including trainings about preventing transmission
                                                    of infectious agents, correct use of PPE and prevention of
                                                    contamination in the clinic of cloth, skin, and environment,
                                                    administrative preventive measures and others - in line with WHO
                                                    guidelines and with an aim to reduce risk of health care workers to
                                                    COVID – 19 exposure and build capacities for case management in case
                                                    of this and next waves of the disease.
                                                </p>
                                            </div>
                                            </div> {/*for registration container */}
                                        </div>
                                    </div>
                                </div>
                                <div className="footer_reset">
                                   {strings.footer} &copy; {" "} {new Date().getFullYear()} Copyright
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ResetPassword;