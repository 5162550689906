import React, {useState} from "react";
import {Button, Modal} from "react-bootstrap";
import Aux from '../../hoc/AuxWrapper';
import {faStamp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {strings} from "../Localization/Localization";


const WatermarkModal = props => {

    const [show, setShow] = useState(false);

    const handleWatermark = () => {
        props.watermark().then(() => {
            setShow(false);
        });
    }

    return (
        <Aux>
            <Button className={"btn btn-info btn-sm " + props.btnClass}
                    onClick={() => setShow(true)}><FontAwesomeIcon icon={faStamp} style={{paddingRight: '4px'}}/>{strings.sign}</Button>
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{strings.signCertificate}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={"container"}>
                        {props.prompt}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" id="edit_modal_close_button" onClick={() => setShow(false)}>{strings.back}</Button>
                    <Button className="btn btn-primary" id="edit_modal_save_button" type="submit" onClick={handleWatermark}>{strings.sign}</Button>
                </Modal.Footer>
            </Modal>
        </Aux>
    );
}


export default WatermarkModal;
