import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Aux from "../../hoc/AuxWrapper";
import "./Modal.css";
import "./CourseCard.css";
import { strings } from "../Localization/Localization";

const AddUserCourseModal = (props) => {
  const [show, setShow] = useState(false);

  const handleАddUserCourse = () => {
    props.addUserCourse().then(() => {
      setShow(false);
    });
  };

  return (
    <Aux>
      <Button
        className={"button-35 " + props.btnClass}
        id="cardButton"
        onClick={() => setShow(true)}
      >
        {strings.signUp}
      </Button>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header className="modal-header">
          <Modal.Title
            style={{
              paddingLeft: "150px",
              fontSize: "30px",
              textTransform: "uppercase",
            }}
          >
            {strings.signUp}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div
            className={"body"}
            style={{ fontSize: "28px", textAlign: "center" }}
          >
            {props.prompt}
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <Button
            variant="secondary"
            onClick={() => setShow(false)}
            className="button-35"
          >
            {strings.cancel}
          </Button>
          <Button
            className="button-35"
            type="submit"
            onClick={handleАddUserCourse}
          >
            {strings.signUp}
          </Button>
        </Modal.Footer>
      </Modal>
    </Aux>
  );
};

export default AddUserCourseModal;
