import axios from '../axios/app';

const UserCourseRepository = {

    getAllUCattachedPaged: (page = 0, size = 10) => {
        return axios.get(`/rest/userCourse/attached/all?page=${page}&size=${size}`)
    },
    deleteAttachedCertificate: (ucId) => {
        return axios.delete(`/rest/userCourse/certificate/${ucId}`)
    },
    singleWatermark: (ucId) => {
        return axios.post(`/rest/userCourse/singleWatermark/${ucId}`)
    },
    addUserCourse: (email, courseId) => {
        return axios.post(`/rest/userCourse/add?email=${email}&courseId=${courseId}`)
    },
    attachedCertificates: () => {
        return axios.get('/rest/userCourse/attached')
    },
    verifiedCertificates: () => {
        return axios.get('/rest/userCourse/verified')
    },
    addAttachment1: (form) => {
        return axios.post('/rest/userCourse/content1', form)
    },
    getUserCourses: (email) => {
        return axios.get(`/rest/userCourse/allUC?email=${email}`)
    },
    getUserCoursesFiltered:(email, page, size,myCoursesFilterDTO) => {
        return axios.post(`rest/userCourse/all-user-courses?email=${email}&page=${page}&size=${size}`, myCoursesFilterDTO)
    },
    getAllUCforWatermark: () => {
        return axios.get(`/rest/userCourse/watermark/all`)
    },
    watermark: (ucIds, points) => {
        return axios.post(`/rest/userCourse/watermark?ucIds=${ucIds}&points=${points}`)
    },
    getUserAtt: (email, courseId) => {
        return axios.get(`/rest/userCourse/getAttachment?email=${email}&courseId=${courseId}`)
    },
    UserCoursesPaged: (lastName, courseName, page = 0, size = 10) => {
        return axios.get(`/rest/userCourse/all?lastName=${lastName}&courseName=${courseName}&page=${page}&size=${size}`)
    },
    allUsersWithPagination:(page,size,courseAttendeesFilterDTO)=>{
        return axios.post(`/rest/userCourse/all-users?page=${page}&size=${size}`,courseAttendeesFilterDTO)
    },
    generateCertificates:(userIds, courseId)=>{
        return axios.post(`rest/userCourse/generate-certificate?userIds=${userIds}&courseId=${courseId}`)
    },
    allSelectedCourses:()=>{
        return axios.get(`rest/userCourse/all-courses-with-listeners`)
    },
    uploadCertificatedDocFile: (request) => {
        return axios.post("/rest/userCourse/admin/attach", request)
    },
    setClickedPre: (courseId) => {
        return axios.patch(`/rest/userCourse/clicked?courseId=${courseId}`)
    },
    downloadCertificate:(email, courseId)=>{
        let config = {
            responseType:"blob",
            headers:{
                "Content-disposition": "attachment; filename=response; charset=UTF-8",
            },
            params: {
             email:email,
             courseId:courseId
            },
          }
    
        return axios.get(`/rest/userCourse/download-attachment`,config)
        

    },
    getAllUsersWithoutPagination:(courseAttendeesFilterDTO)=>{
        return axios.post(`rest/userCourse/list-of-users`,courseAttendeesFilterDTO);
    }
    
};
export default UserCourseRepository;
