import axios from '../axios/app';

const UserRepository = {

    registerUser: (request) => {
        return axios.post("/rest/user/register", request)
    },
    getUsersStatistics:()=>{
        return axios.get('/rest/user/statistic')
    },
    bulkMail:(workingPosition,subject,message)=>{
        return axios.post(`/rest/user/bulk/mail?workingPosition=${workingPosition}&subject=${subject}&message=${message}`)
    },
    getUserDetails:()=>{
        return axios.get(`rest/user/userDetails`);
    }

};
export default UserRepository;