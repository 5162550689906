import axios from '../axios/app';
const coursesRepository = {

    getActiveCourses: (email,page, size, availableCoursesFilterDto) => {
        return axios.post(`/rest/course/home?email=${email}&page=${page}&size=${size}`, availableCoursesFilterDto);
    },
    getMyCourses: (email) => {
        return axios.get(`/rest/course/mycourses?email=${email}`)
    },
    editCourse: (form) => {
        return axios.patch('/rest/course/update', form)
    },
    addCourse: (form) => {
        return axios.post(`/rest/course/add`, form)
    },
    getCoursesPage: (page = 0, size = 10,allCoursesFilterDTO) => {
        return axios.post(`/rest/course/getAll?page=${page}&size=${size}`,allCoursesFilterDTO)
    }

};
export default coursesRepository;