import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import UserCourseRepository from "../../repository/UserCourseRepository";
import CrudModal from "../Courses/CrudModal";
import AddCertificatedDoc from "./AddCertificatedDoc";
import { toast } from "react-toastify";
import { strings } from "../Localization/Localization";
import Select from "react-select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "3px #0085EF",
    color: state.isSelected ? "yellow" : "black",
    backgroundColor: state.isSelected ? "#0085EF" : "white",
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: "20px",
    backgroundColor: "white",
    boxShadow: "rgb(0 0 0 / 15%) 0px 5px 15px",
    height: "calc(1.5em + 1.3rem + 2px)",
    border: "1px solid #F3F3F3",
    color: "black",
    fontSize: "1rem",
    width: "100%",
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "black",
  }),
};
class AdminAttachingDocumentForUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userCourses: [],
      allUserCourses: [],
      courseSelected: null,
      userIds: [],
      userIndexes: [],
      totalItems: 0,
      page: 0,
      selectAll: false,
      allCoursesSelected: [],
      pageCount: 1,
      pageSize: 15,
      firstName: "",
      lastName: "",
      receivedFile: null,
      courseName: "",
      courseAttendeesFilterDTO: {
        lastName: null,
        courseId: null,
      },
    };
  }

  // fetchData = (selectedPage = 0) => {
  //     UserCourseRepository.UserCoursesPaged(this.state.lastName, this.state.courseName, selectedPage).then(r => {
  //         console.log(r.data)
  //         this.setState({
  //             userCourses: r.data.content,
  //             pageCount: r.data.totalPages
  //         })
  //     }).catch(err => {
  //         console.log(err)
  //     })
  // }
  getAllUsersWithoutPagination = () => {
    UserCourseRepository.getAllUsersWithoutPagination(
      this.state.courseAttendeesFilterDTO
    ).then((res) => {
      this.setState({
        allUserCourses: res.data,
      });
    });
  };
  fetchData = (page = 0, size = this.state.pageSize) => {
    UserCourseRepository.allUsersWithPagination(
      page,
      size,
      this.state.courseAttendeesFilterDTO
    )
      .then((r) => {
        console.log(r.data);
        this.setState({
          totalItems: r.data.totalElements,
          userCourses: r.data.content,
          pageCount: r.data.totalPages,
        });
        let newListIndexes = [];
        r.data.content.map((n, i) => {
          const index = i + r.data.pageable.pageNumber * this.state.pageSize;
          newListIndexes.push(index);
        });
        this.setState({
          selectAll: newListIndexes.every((element) => {
            return this.state.userIndexes.includes(element);
          }),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getAllSelectedCourses = () => {
    UserCourseRepository.allSelectedCourses().then((response) => {
      this.setState({
        allCoursesSelected: response.data,
      });
    });
  };
  componentDidMount() {
    this.fetchData();
    this.getAllSelectedCourses();
    this.getAllUsersWithoutPagination();
  }

  handlePageClick = (data) => {
    this.fetchData(data.selected);
    this.setState({
      page: data.selected,
    });
  };

  handleSearch = (e) => {
    let lastName = e.target.value;
    this.setState(
      {
        courseAttendeesFilterDTO: {
          ...this.state.courseAttendeesFilterDTO,
          lastName: lastName,
        },
      },
      () => this.fetchData()
    );
    if (e.target.value === "") {
      this.setState(
        {
          courseAttendeesFilterDTO: {
            ...this.state.courseAttendeesFilterDTO,
            lastName: null,
          },
        },
        () => this.fetchData()
      );
    }
  };
  // handleChangeSearchString = (event) => {
  //   if (event.key === "Enter") {
  //     this.setState(
  //       {
  //         courseAttendeesFilterDTO: {
  //           ...this.state.courseAttendeesFilterDTO,
  //           lastName: event.target.value,
  //         },
  //       },
  //       () => {
  //         this.fetchData();
  //       }
  //     );
  //   }
  //   if (event.target.value === "") {
  //     this.setState({
  //       courseAttendeesFilterDTO: {
  //         ...this.state.courseAttendeesFilterDTO,
  //         lastName: null,
  //       },
  //     });
  //   }
  // };
  clearSearchString = () => {
    this.setState(
      {
        courseAttendeesFilterDTO: {
          ...this.state.courseAttendeesFilterDTO,
          lastName: null,
        },
      },
      () => {
        this.fetchData();
        this.getAllUsersWithoutPagination();
      }
    );
    document.getElementById("searchBar").value = "";
  };

  handleCourseName = (selected) => {
    this.setState({
      courseSelected: selected,
    });
    if (selected !== this.state.courseSelected) {
      this.setState({
        userIds: [],
        userIndexes: [],
        selectAll: false,
      });
    }
    if (selected !== null) {
      this.setState(
        {
          courseAttendeesFilterDTO: {
            ...this.state.courseAttendeesFilterDTO,
            courseId: selected.value,
          },
        },
        () => {
          this.fetchData();
          this.getAllUsersWithoutPagination();
        }
      );
    }
    if (selected === null) {
      this.setState({
        courseSelected: null,
        userIds: [],
        userIndexes: [],
        selectAll: false,
      });
      this.setState(
        {
          courseAttendeesFilterDTO: {
            ...this.state.courseAttendeesFilterDTO,
            courseId: null,
          },
        },
        () => {
          this.fetchData();
          this.getAllUsersWithoutPagination();
        }
      );
    }
  };
  handleChangeCheckbox = (e) => {
    let newListUsers = [...this.state.userIds];
    if (e.target.checked) {
      newListUsers = [...this.state.userIds, e.target.value];
    } else {
      newListUsers.splice(this.state.userIds.indexOf(e.target.value), 1);
    }
    this.setState({
      userIds: newListUsers,
    });
  };

  handleSelectAllClick = (event) => {
    const newListIndexes = [];
    this.state.userCourses.map((n, i) => {
      const index = i + this.state.page * this.state.pageSize;
      newListIndexes.push(index);
    });
    if (event.target.checked) {
      var difference = this.state.userIndexes.filter(
        (x) => newListIndexes.indexOf(x) === -1
      );
      const newSelecteds = this.state.userCourses.map((n) => n.userId);
      this.setState({
        userIds: newSelecteds,
        selectAll: true,
        userIndexes: newListIndexes.concat(difference),
      });
      return;
    }
    var difference = this.state.userIndexes.filter(
      (x) => newListIndexes.indexOf(x) === -1
    );

    this.setState({
      userIds: [],
      userIndexes: difference,
      selectAll: false,
    });
  };
  handleClickIndexes = (event, name) => {
    const selectedIndex = this.state.userIndexes.indexOf(name);
    let newSelected = [];
    let newListIndexes = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.userIndexes, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.userIndexes.slice(1));
    } else if (selectedIndex === this.state.userIndexes - 1) {
      newSelected = newSelected.concat(this.state.userIndexes.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.state.userIndexes.slice(0, selectedIndex),
        this.state.userIndexes.slice(selectedIndex + 1)
      );
    }
    this.setState({
      userIndexes: newSelected,
    });
    this.state.userCourses.map((n, i) => {
      const index = i + this.state.page * this.state.pageSize;
      newListIndexes.push(index);
    });
    this.setState({
      selectAll: newListIndexes.every((element) => {
        return newSelected.includes(element);
      }),
    });
  };

  isSelected = (name) => this.state.userIndexes.indexOf(name) !== -1;
  generateCertificate = () => {
    let newListUserIds = [];
    this.state.allUserCourses.map((n, i) => {
      if (this.isSelected(i)) {
        newListUserIds.push(this.state.allUserCourses[i].userId);
      }
    });
    this.state.userIds = newListUserIds;
    if (this.state.courseAttendeesFilterDTO.courseId === null) {
      toast.error(strings.selectCourse);
    } else if (this.state.userIds.length === 0) {
      toast.error(strings.selectUsers);
    } else {
      UserCourseRepository.generateCertificates(
        this.state.userIds,
        this.state.courseAttendeesFilterDTO.courseId
      )
        .then((res) => {
          toast.success(strings.successfullyGeneratedCertificates);
          this.setState({
            userIds: [],
            userIndexes: [],
            selectAll: false,
          });
          setTimeout(() => {
            location.reload();
          }, 3000);
        })
        .catch((error) => {
          if (
            error.response.data.message ===
            "Course does not have template for generating certificate!"
          ) {
            toast.error(strings.noTemplate);
          }
        });
    }
  };
  showMessageForDisabledCheckbox = (message, id) => {
    var idCheckbox = document.getElementById(id);
    if (idCheckbox.disabled) {
      toast.error(strings.selectCourse);
    } else {
    }
  };
  render() {
    return (
      <div className="container mt-5 mb-2">
        <div style={{ display: "flex" }} className="filterCourses">
          <div style={{ position: "relative" }}>
            <input
              id="searchBar"
              className="form-control"
              placeholder={strings.surname}
              onChange={this.handleSearch}

              // onKeyPress={this.handleChangeSearchString}
            />
            {this.state.courseAttendeesFilterDTO.lastName !== null && (
              <i
                style={{
                  webkitTextStroke: "0.5px white",
                  cursor: "pointer",
                  position: "absolute",
                  verticalAlign: "middle",
                  right: "10px",
                  top: 5,
                  height: "100%",
                  fontSize: "17px",
                }}
                class="fa fa-times mt-2 ml-1"
                aria-hidden="true"
                onClick={this.clearSearchString}
              ></i>
            )}

            {/* <i
              class="fa fa-search ml-2 mt-2"
              aria-hidden="true"
              style={{ fontSize: "30px", cursor: "pointer" }}
              onClick={() => this.fetchData()}
            ></i> */}
          </div>
          {/* <div className="col-3"><input className="form-control" placeholder={strings.course}
                                                  onChange={this.handleCourseName}/></div> */}
          <br className="d-md-none" />
          <Select
            className="ml-lg-4 col-lg-3"
            placeholder={strings.course}
            styles={customStyles}
            isClearable={true}
            closeMenuOnSelect={true}
            isSearchable={true}
            options={this.state.allCoursesSelected?.map((item) => ({
              value: item.courseId,
              label: item.courseName,
            }))}
            value={this.state.courseSelected || null}
            onChange={this.handleCourseName}
          />
          <br className="d-md-none" />

          <button
            id="generateCertificate"
            className="btn btn-info btn-sm"
            style={{ marginLeft: "400px" }}
            onClick={this.generateCertificate}
          >
            {strings.generateCertificate}
          </button>
        </div>
        <br className="d-md-none" />

        <TableContainer className="table table-hover mt-5">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div
                    style={{
                      display: "flex",
                      position: "relative",
                      top: "13px",
                    }}
                  >
                    <p style={{ marginTop: "10px" }}>{strings.all}</p>
                    <div
                      onClick={() =>
                        this.showMessageForDisabledCheckbox(
                          this,
                          this.state.page
                        )
                      }
                    >
                      <Checkbox
                        id={this.state.page}
                        key={this.state.page}
                        checked={this.state.selectAll}
                        disabled={
                          this.state.courseAttendeesFilterDTO.courseId === null
                            ? true
                            : false
                        }
                        color="primary"
                        onChange={this.handleSelectAllClick}
                      />
                    </div>
                  </div>
                </TableCell>
                <TableCell>{strings.name}</TableCell>
                <TableCell>{strings.surname}</TableCell>
                <TableCell>{strings.email}</TableCell>
                <TableCell>{strings.course}</TableCell>
              </TableRow>
              {/* <th>{strings.action}</th> */}
            </TableHead>
            <TableBody>
              {this.state.userCourses.map((uc, i) => {
                const index = i + this.state.page * this.state.pageSize;
                const isItemSelected = this.isSelected(index);
                return (
                  <TableRow
                    key={index}
                    style={{ borderBottom: "none" }}
                    role="checkbox"
                  >
                    <TableCell padding="checkbox">
                      <div
                        onClick={() =>
                          this.showMessageForDisabledCheckbox(this, index)
                        }
                      >
                        <Checkbox
                          value={uc.userId}
                          id={index}
                          disabled={
                            this.state.courseAttendeesFilterDTO.courseId ===
                            null
                              ? true
                              : false
                          }
                          onClick={(event) => {
                            this.handleChangeCheckbox(event);
                            this.handleClickIndexes(event, index);
                          }}
                          checked={isItemSelected}
                        />
                      </div>
                    </TableCell>
                    <TableCell>{uc.firstName}</TableCell>
                    <TableCell>{uc.lastName}</TableCell>
                    <TableCell>{uc.email}</TableCell>
                    <TableCell>{uc.courseName}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="text-center">
          <ReactPaginate
            previousLabel={strings.previous}
            nextLabel={strings.next}
            breakLabel={"..."}
            pageCount={this.state.pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>
    );
  }

  handleReceivedFile = (receivedFile) => {
    this.setState({
      receivedFile: receivedFile,
    });
  };

  submitAttachment = (uc) => {
    if (this.state.receivedFile !== null) {
      const form = new FormData();
      form.append("userId", uc.user.id);
      form.append("courseId", uc.course.id);
      form.append("file", this.state.receivedFile);

      return UserCourseRepository.uploadCertificatedDocFile(form)
        .then((r) => {
          toast.success(strings.documentUploadSuccess);
          // window.setTimeout(reloadPage, 3000);
        })
        .catch(() => {
          toast.error(strings.documentUploadFail);
        });
    } else {
      toast.warn(strings.documentUploadUnsigned);
    }
  };

  // renderUserCourses = (uc, index) => {
  //   return (
  //     <tr>

  //       <td>
  //         <div style={{ display: "flex" }}>
  //           <input
  //             type="checkbox"
  //             style={{ marginTop: "2px" }}
  //             value={uc.userId}
  //             onChange={this.handleChangeCheckbox}
  //           />
  //           <p style={{ whiteSpace: "nowrap" }} className="ml-4">
  //             {" "}
  //             {uc.firstName}
  //           </p>
  //         </div>
  //       </td>
  //       <td>{uc.lastName}</td>
  //       <td>{uc.email}</td>
  //       <td>{uc.courseName}</td>
  //       {/* {!uc.userUpload ?
  //               <td>
  //                   <CrudModal
  //                       entity={uc}
  //                       btnClass="btn-info btn-sm"
  //                       title={strings.uploadButton}
  //                       icon={"Add"}
  //                       onSubmit={() => this.submitAttachment(uc)}
  //                       body={AddCertificatedDoc}
  //                       receivedFile={this.handleReceivedFile}
  //                   />
  //               </td>
  //               : <td></td>} */}
  //     </tr>
  //   );
  // };
}
export default AdminAttachingDocumentForUser;
