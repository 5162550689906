import React, { Component } from "react";
import { ButtonToolbar } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CoursesRepository from "../../repository/CoursesRepository";
import EditCourse from "./EditCourse";
import CrudModal from "./CrudModal";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import CategoryRepository from "../../repository/CategoryRepository";
import { strings } from "../Localization/Localization";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <div style={{ display: "flex", justifyContent: "start" }}>
          <input
            className="mt-1"
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />
          <label className="ml-2">{props.label}</label>
          {"  "}
        </div>
      </components.Option>
    </div>
  );
};
const colourStyles = {
  control: (base, state) => ({
    ...base,
    borderRadius: "10px",
    textTransform: "uppercase",
    background: state.isFocused ? "#007BFF" : "#007BFF",
    minWidth: "250px",
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "white",
    display: "flex",
    fontSize: "15px",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    svg: {
      fill: "white",
    },
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected ? "#D6EDFF" : "white",
    color: state.isSelected ? "black" : "black",
    "&:hover": {
      background: state.isSelected ? "#D6EDFF" : "#F9F9F9",
    },
  }),
  indicatorSeparator: (base, state) => ({
    ...base,
    display: "none",
  }),
};
class Courses extends Component {
  state = {
    courses: [],
    pageCount: 1,
    categories: [],
    optionSelected: null,
    optionSelectedLanguage: null,
    allCoursesFilterDTO: {
      searchString: null,
      language: null,
      categoryIds: null,
    },
    // image: {}
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = (selectedPage = 0, size = 15) => {
    CoursesRepository.getCoursesPage(
      selectedPage,
      size,
      this.state.allCoursesFilterDTO
    )
      .then((res) => {
        CategoryRepository.getAllCategories().then((snap) => {
          console.log(res.data.content);
          this.setState({
            courses: res.data.content,
            pageCount: res.data.totalPages,
            categories: snap.data,
            image: res.data,
            template: res.data,
          });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handleChange = (selected) => {
    let listCatIds = [];
    this.setState({
      optionSelected: selected,
    });
    for (var i = 0; i < selected.length; i++) {
      listCatIds.push(selected[i].value);
    }
    this.setState(
      {
        allCoursesFilterDTO: {
          ...this.state.allCoursesFilterDTO,
          categoryIds: listCatIds,
        },
      },
      () => {
        this.fetchData();
      }
    );
    if (listCatIds.length === 0) {
      this.setState(
        {
          allCoursesFilterDTO: {
            ...this.state.allCoursesFilterDTO,
            categoryIds: null,
          },
        },
        () => {
          this.fetchData();
        }
      );
    }
  };
  handleChangeLanguages = (selected) => {
    this.setState({
      optionSelectedLanguage: selected,
    });
    if (
      this.state.optionSelectedLanguage !== null &&
      selected.value === this.state.optionSelectedLanguage.value
    ) {
      this.setState({
        optionSelectedLanguage: null,
      });
      this.setState(
        {
          allCoursesFilterDTO: {
            ...this.state.allCoursesFilterDTO,
            language: null,
          },
        },
        () => {
          this.fetchData();
        }
      );
    } else {
      this.setState(
        {
          allCoursesFilterDTO: {
            ...this.state.allCoursesFilterDTO,
            language: selected.value,
          },
        },
        () => {
          this.fetchData();
        }
      );
    }
  };
  handleChangeSearchString = (event) => {
    if (event.key === "Enter") {
      this.setState(
        {
          availableCoursesFilterDto: {
            ...this.state.allCoursesFilterDTO,
            searchString: event.target.value,
          },
        },
        () => {
          this.fetchData();
        }
      );
    }
    if (event.target.value === "") {
      this.setState({
        allCoursesFilterDTO: {
          ...this.state.allCoursesFilterDTO,
          searchString: null,
        },
      });
    }
  };
  handleChangeSearch = (event) => {
    this.setState({
      allCoursesFilterDTO: {
        ...this.state.allCoursesFilterDTO,
        searchString: event.target.value,
      },
    });
    if (event.target.value === "") {
      this.setState(
        {
          allCoursesFilterDTO: {
            ...this.state.allCoursesFilterDTO,
            searchString: null,
          },
        },
        () => this.fetchData()
      );
    }
  };
  clearSearchString = () => {
    this.setState(
      {
        allCoursesFilterDTO: {
          ...this.state.allCoursesFilterDTO,
          searchString: null,
        },
      },
      () => this.fetchData()
    );
    document.getElementById("searchBar").value = "";
  };
  handlePageClick = (data) => {
    this.fetchData(data.selected);
  };

  onEdit = (entity, image) => {
    // entity.categories = {
    //     nameMk: entity.categoryNameMk
    // }
    // entity.upatstvo={};
    // entity.file = {
    //     image: entity.image,
    //     imageName: entity.imageName,
    //     imageType: entity.imageType
    // }
    const imageTmp = entity.image;

    const templateTmp = entity.template;

    const form = new FormData();
    // form.append("course", entity)
    form.append("courseId", entity.id);
    form.append("image", imageTmp);
    form.append("description", entity.description);
    form.append("name", entity.name);
    form.append("link", entity.link);
    form.append("evaluationLinkPre", entity.evaluationLinkPre);
    form.append("evaluationLinkPost", entity.evaluationLinkPost);
    form.append("points", entity.points);
    form.append("userUpload", entity.userUpload);
    form.append("evaluation", entity.evaluation);
    form.append("categoryId", entity.category?.id);
    form.append("sortNo", entity.sortNo);
    form.append("status", entity.status);
    form.append("language", entity.language);
    form.append("template", templateTmp);
    if (entity.dateFrom === null && entity.dateTo !== null) {
      return toast.error(strings.toastOneDateFail);
    } else if (entity.dateTo === null && entity.dateFrom !== null) {
      return toast.error(strings.toastOneDateFail);
    } else if (entity.dateTo === "" && entity.dateFrom !== null) {
      return toast.error(strings.toastOneDateFail);
    } else if (entity.dateFrom === "" && entity.dateTo !== null) {
      return toast.error(strings.toastOneDateFail);
    }

    let dateFrom = new Date(entity.dateFrom);
    let dateTo = new Date(entity.dateTo);
    if (dateFrom > dateTo && dateTo < dateFrom) {
      return toast.error(strings.validDate);
    }
    // debugger;
    else {
      if (entity.dateFrom) {
        form.append("dateFrom", entity.dateFrom);
      }
      if (entity.dateTo) {
        form.append("dateTo", entity.dateTo);
      }
      return CoursesRepository.editCourse(form)
        .then((res) => {
          toast.success(strings.editToastSuccess);
          this.fetchData();
          // setTimeout(() => {
          //   location.reload();
          // }, 1500);
        })
        .catch((error) => {
          if (error.response.data.message === "Image type not acceptable!") {
            return toast.error(strings.imageValid);
          }
        });
    }
  };
  showCategory = (item) => {
    if (localStorage.getItem("activeLanguage") === "mk") {
      return item.category.nameMk;
    } else if (localStorage.getItem("activeLanguage") === "al") {
      return item.category.nameAl;
    } else if (localStorage.getItem("activeLanguage") === "en") {
      return item.category.nameEn;
    }
  };
  renderCoursesItem = (course, image) => {
    course.categories = this.state.categories;

    return (
      <tr key={course.id}>
        <td>{course.name}</td>
        <td>{course.description}</td>
        <td>{this.showCategory(course)}</td>
        <td>{course.link}</td>
        <td>
          {course.status === true
            ? strings.courseStatusOptionActive
            : strings.courseStatusOptionInactive}
        </td>
        <td>{course.points}</td>
        <td>{course.language}</td>
        <td colSpan={5}>
          <ButtonToolbar
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CrudModal
              entity={course}
              btnClass="btn-success btn-sm"
              title={strings.editButton}
              icon={"Edit"}
              onSubmit={this.onEdit}
              body={EditCourse}
            />
          </ButtonToolbar>
        </td>
      </tr>
    );
  };

  render() {
    const optionsLanguages = [
      { value: "Македонски", label: `${strings.macedonian}` },
      { value: "Shqip", label: `${strings.albanian}` },
      { value: "English", label: `${strings.english}` },
    ];
    const options = this.state.categories.map((cat) => ({
      value: cat.id,
      label:
        localStorage.getItem("activeLanguage") === "mk"
          ? cat.nameMk
          : localStorage.getItem("activeLanguage") === "al"
          ? cat.nameAl
          : localStorage.getItem("activeLanguage") === "en"
          ? cat.nameEn
          : null,
    }));
    return (
      <>
        <div className="container">
          <div
            className="mt-3 mb-4 filterDiv"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Link
              to="/course/add"
              className="btn btn-primary btn-sm"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <FontAwesomeIcon
                icon={faPlusCircle}
                style={{ paddingRight: "4px" }}
              />
              {strings.addButton}
            </Link>
            <ReactSelect
              placeholder={strings.languageOfCourse}
              className="mt-1 ml-xl-3"
              isMulti={false}
              options={optionsLanguages}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              isSearchable={false}
              styles={colourStyles}
              controlShouldRenderValue={false}
              isClearable={false}
              value={this.state.optionSelectedLanguage}
              components={{
                Option,
              }}
              onChange={this.handleChangeLanguages}
              allowSelectAll={true}
            />
            <ReactSelect
              id="categorySelect"
              placeholder={strings.category}
              className="mt-1 ml-xl-3"
              options={options}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              isSearchable={false}
              styles={colourStyles}
              controlShouldRenderValue={false}
              isClearable={false}
              components={{
                Option,
              }}
              onChange={this.handleChange}
              allowSelectAll={true}
              value={this.state.optionSelected}
            />
            <br class="d-md-none" />
            <div className="p-1 searchDiv mr-lg-1 mt-lg-1">
              <div style={{ position: "relative" }}>
                <input
                  id="searchBar"
                  style={{
                    border: "1px solid lightgrey",
                    height: "30px",
                    borderRadius: "10px",
                    fontSize: "14px",
                    minWidth: "220px",
                  }}
                  onChange={this.handleChangeSearch}
                  onKeyPress={this.handleChangeSearchString}
                  placeholder={strings.search}
                  type="text"
                />
                {this.state.allCoursesFilterDTO.searchString !== null && (
                  <i
                    style={{
                      webkitTextStroke: "0.5px white",
                      cursor: "pointer",
                      position: "absolute",
                      verticalAlign: "middle",
                      right: "10px",
                      top: 0,
                      height: "100%",
                    }}
                    class="fa fa-times mt-2 ml-1"
                    aria-hidden="true"
                    onClick={this.clearSearchString}
                  ></i>
                )}
              </div>
              <i
                class="fa fa-search ml-2"
                aria-hidden="true"
                style={{ fontSize: "30px", cursor: "pointer" }}
                onClick={() => this.fetchData()}
              ></i>
            </div>
          </div>
          <table className="table table-hover">
            <thead>
              <tr>
                <th>{strings.name}</th>
                <th>{strings.courseDescription}</th>
                <th>{strings.category}</th>
                <th>{strings.courseLink}</th>
                <th>{strings.courseStatus}</th>
                <th>{strings.coursePoints}</th>
                <th>{strings.courseLanguage}</th>
                <th className="text-center">{strings.action}</th>
              </tr>
            </thead>
            <tbody>{this.state.courses.map(this.renderCoursesItem)}</tbody>
          </table>
          <div>
            <ReactPaginate
              previousLabel={strings.prevPage}
              nextLabel={strings.nextPage}
              breakLabel={"..."}
              pageCount={this.state.pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </>
    );
  }
}

export default Courses;
