import axios from '../axios/app';

const categoryRepository = {

    getAllCategories: () => {
        return axios.get('/rest/category/all')
    },
    editCategory: (entity) => {
        return axios.put('/rest/category/update', entity)
    },
    addCategory: (form) => {
        return axios.post(`/rest/category/create`, form)
    },
    getCategories: (page = 0, size = 10) => {
        return axios.get(`/rest/category/all-paged?page=${page}&size=${size}`)
    }
};

export default categoryRepository;