import React, {Component} from "react";
import {strings} from "../Localization/Localization";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {year: new Date().getFullYear()}
    }

    render() {
        return (
            <footer className="page-footer">


                <div className="footer-copyright text-center py-5">{" "} {strings.footer} © {new Date().getFullYear()} Copyright&nbsp;
                    {/*<a target="_blank" rel="noopener noreferrer" href="https://inteligenta.io/"*/}
                    {/*   style={{color: "white"}}><strong>INTELIGENTA</strong></a>*/}
                </div>

            </footer>
        );
    }

}

export default Footer;