import React, {useState} from "react";
import {Button, Modal} from "react-bootstrap";
import Aux from '../../hoc/AuxWrapper';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {strings} from "../Localization/Localization";


const DeleteAttachedCert = props => {

    const [show, setShow] = useState(false);

    const handleDeleteCert = () => {
        props.deleteCertificate().then(() => {
            setShow(false);
        });
    }

    return (
        <Aux>
            <Button className={"btn btn-danger btn-sm ml-2 mt-2"}
                    onClick={() => setShow(true)}><FontAwesomeIcon icon={faTrash} style={{paddingRight: '4px'}}/>{strings.delete}</Button>
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{strings.deleteUploadedCertificate}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={"container"}>
                        {props.prompt}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>{strings.back}</Button>
                    <Button className="btn btn-danger" type="submit" onClick={handleDeleteCert}>{strings.delete}</Button>
                </Modal.Footer>
            </Modal>
        </Aux>
    );
}


export default DeleteAttachedCert;
