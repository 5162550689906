import React, {useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {strings} from "../Localization/Localization";
import {toast} from "react-toastify";


const EditCategory = (props) => {

    return (
        <Row>
            <Col>
                <Form>
                    <Form.Group controlId="name">
                        <Form.Label>{strings.macedonianTitle} <a style={{color:"white", fontWeight:"bold"}}>{strings.required}</a></Form.Label>
                        <Form.Control
                            type="text"
                            name="nameMk"
                            required
                            defaultValue={props.entity.nameMk}
                            onChange={props.handleChange}/>
                    </Form.Group>
                    <Form.Group controlId="name">
                        <Form.Label>{strings.albanianTitle} </Form.Label>
                        <Form.Control
                            type="text"
                            name="nameAl"
                            defaultValue={props.entity.nameAl}
                            onChange={props.handleChange}/>
                    </Form.Group>
                    <Form.Group controlId="name">
                        <Form.Label>{strings.englishTitle} </Form.Label>
                        <Form.Control
                            type="text"
                            name="nameEn"
                            defaultValue={props.entity.nameEn}
                            onChange={props.handleChange}/>
                    </Form.Group>
                </Form>
            </Col>
        </Row>
    )
}

export default EditCategory;
