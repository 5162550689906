import React, {Component} from 'react';
import '../../assets/css/theme/login-6.css';
import './Login.css';
import AuthenticationService from "../../repository/service/AuthenticationService";
import {AUTH_TOKEN} from "../../shared/utility";
import Background from '../../assets/images/Pozadina-Login-1.png'
import {NavLink} from "react-router-dom";
import axios from "../../axios/app";
import "../Registration/Registration"

import {strings} from "../Localization/Localization";

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            loginError: null,
            role: "",
            // activeLanguage: ""
        }
    }

    setLanguageToMacedonian = () => {
        strings.setLanguage('mk');
        localStorage.setItem("activeLanguage", "mk")
        window.location.reload();
    }

    setLanguageToAlbanian = () => {
        strings.setLanguage('al');
        localStorage.setItem("activeLanguage", "al")
        window.location.reload();
    }

    setLanguageToEnglish = () => {
        strings.setLanguage('en');
        localStorage.setItem("activeLanguage", "en")
        window.location.reload();
    }

    fetchRole() {
        axios.get("/rest/user/userDetails").then((data) => {
            localStorage.setItem('userEmail', data.data[0]);
            localStorage.setItem('userDetails', data.data[1]);
            localStorage.setItem('activeLanguage', 'mk')
            this.props.history.push('/');
        });
    };

    loginHandler(e) {
        e.preventDefault();
        AuthenticationService.loginUser({
            email: this.state.username,
            password: this.state.password
        }).then(response => {
            localStorage.setItem(AUTH_TOKEN, response.data);
            this.clearErrorMessage();
            this.fetchRole();
        }).catch(error => {
            this.setErrorMessage(error.response);
            alert(`${strings.validationEmail}`);
        });
    }

    usernameChangeHandler(e) {
        this.setState({
            username: e.target.value
        });
    }

    passwordChangeHandler(e) {
        this.setState({
            password: e.target.value
        });
    }

    clearErrorMessage() {
        this.setState({
            loginError: null
        });
    }

    setErrorMessage(statusCode) {
        if (statusCode === 403) {
            this.setState({
                loginError: 'Email or password are incorrect'
            });
        } else {
            this.setState({
                loginError: 'Server temporarily unavailable'
            });
        }
    }


    render() {
        return (
            <div className="kt-grid kt-grid--ver kt-grid--root kt-page kt-grid-page">
                <div className="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v6 kt-login--signin"
                     id="kt_login">
                    <div
                        className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
                        <div
                            className="kt-grid__item  kt-grid__item--order-tablet-and-mobile-2  kt-grid kt-grid--hor kt-login__aside">
                            <div className="kt-login__wrapper">
                                <div className="kt-login__container">

                                    <div className="kt-login__body">
                                        <div className="kt-login__logo" id="img1">
                                            <a href="!#">
                                                <img src={require('../../assets/images/MZ.png')}
                                                     className={"img-responsive p-0 "} style={{width: "100%"}} alt=""/>
                                            </a>
                                        </div>
                                        <div className="kt-login__signin container box-shadow"
                                             style={{borderRadius: "10px"}}>

                                            <div className="kt-login__logo">
                                                <a href="!#">
                                                    <img
                                                        src={require('../../assets/images/cropped-cropped-e-health.jpg')}
                                                        className={"img-responsive text-center mt-4"}
                                                        style={{width: "50%"}}
                                                        alt=""/>
                                                    {/*<img src={require('../../assets/images/usaid3.png')}*/}
                                                    {/*     className={"img-responsive"} style={{width: "50%"}} alt=""/>*/}

                                                </a>


                                                <div className="kt-login__head row container">
                                                    <NavLink className=" col " id="login" to="/login">{strings.login}</NavLink>
                                                    <NavLink className="col" id="signup" to="/registration">{strings.register}</NavLink>

                                                </div>
                                            </div>
                                            <div className="kt-login__form">
                                                <form className="kt-form" onSubmit={(e) => this.loginHandler(e)}>
                                                    <div className="form-group"><span id="email">{strings.email}</span>
                                                        <input className="form-control border " type="text"
                                                               onChange={(e) => this.usernameChangeHandler(e)}
                                                               name="email" autoComplete="off"
                                                               style={{paddingLeft: "15px"}}/>
                                                    </div>
                                                    <div className="form-group"><span id="pass">{strings.password}</span>
                                                        <input type="password"
                                                               onChange={(e) => this.passwordChangeHandler(e)}
                                                               className="form-control form-control-last border"
                                                               name="password" style={{paddingLeft: "15px"}}/>
                                                    </div>
                                                    <div className="row" id="form2">
                                                        <div className="kt-login__extra col">
                                                            <label className="kt-checkbox">
                                                                <input type="checkbox" name="remember" id="remember"/><p
                                                                style={{fontWeight: "bold"}}>{strings.save}</p>
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                        <div className="kt-login__extra col">
                                                            {/*<NavLink to={'/registration'}><span id="kt_login_forgot">Креирајте корисничка сметка</span></NavLink>*/}
                                                            <NavLink to={'/reset/password'} style={{
                                                                textDecoration: "none",
                                                                fontWeight: "bold",
                                                                whiteSpace:"nowrap"
                                                            }}><span
                                                                id="kt_login_forgot">{strings.forgotPassword}</span></NavLink>
                                                        </div>
                                                    </div>

                                                    <div className="kt-login__actions">
                                                        <button type="submit" id="kt_login_signin_submit"
                                                                className="btn btn-brand btn-pill btn-elevate">{strings.login}
                                                        </button>

                                            <div className="row mt-4">
                                                {/*<div className="col-4"/>*/}
                                                    <div className="col-12 text-center">
                                                          <span>
                                                            <button type="button"
                                                                     id="languageButton"
                                                                    className="btn btn-brand btn-pill btn-elevate"
                                                                    style={{paddingLeft: "18px", paddingRight: "20px", backgroundColor:"#0061AF"}}
                                                                    onClick={this.setLanguageToMacedonian}>MK</button>
                                                            <span style={{fontSize: '12px'}}>
                                                            </span>
                                                            <button type="button"
                                                                     id="languageButton"
                                                                    className="btn btn-brand btn-pill btn-elevate"
                                                                    style={{paddingLeft: "18px", paddingRight: "20px", backgroundColor:"#0061AF"}}
                                                                    onClick={this.setLanguageToAlbanian}>SQ</button>
                                                              <span style={{fontSize: '12px'}}>
                                                            </span>
                                                              <button type="button"
                                                                       id="languageButton"
                                                                      className="btn btn-brand btn-pill btn-elevate"
                                                                      style={{paddingLeft: "18px", paddingRight: "20px", backgroundColor:"#0061AF"}}
                                                                      onClick={this.setLanguageToEnglish}>EN</button>
                                                          </span>
                                                    </div>
                                                </div>

                                                        <div className="col mt-3 p-2" style={{color: "black"}}>{strings.account}{" "}
                                                        <a href="/registration" id='reg'>{strings.register}</a>
                                                        </div>
                                                    </div>


                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <footer>
                                <div className="row">
                                    <div className="col-4"></div>
                                    <div className="col-4">
                                        <div className="row">
                                            <p style={{
                                                position: "relative",
                                                top: "30px",
                                                color: "grey",
                                                left: "75px",
                                                fontSize: "14px"
                                            }}>{strings.supportedBy}</p>
                                        </div>
                                        <div className="row">
                                            <img src={require('../../assets/images/usaid3.png')}
                                                 className={"img-responsive"} style={{width: "100%"}} alt=""/>
                                        </div>
                                    </div>
                                    <div className="col-4"></div>


                                </div>
                            </footer>
                        </div>
                        <div
                            className="kt-grid__item kt-grid__item--fluid kt-grid__item--center kt-grid kt-grid--ver kt-login__content"
                            style={{backgroundImage: "url(" + Background + ")"}}>

                            <div className="kt-login__section">
                                <div className="kt-login__block">
                                    <div className="kt-login__desc">
                                        <div>
                                            <div className="registrationTextDiv">
                                                {/*<div style={{width: "80%"}}>*/}
                                                    <h5>Регистрација за посета на обуки за здравствени работници</h5>
                                                    <br/>
                                                    <p className="registrationText">
                                                        Како дел од одговорот против КОВИД-19 во Република Северна
                                                        Македонија, СЗО поддржува воведување / адаптација на специфични
                                                        модули за обука за KОВИД - 19, вклучително и обуки за спречување
                                                        пренесување на заразни агенси, правилна употреба на ЛЗО и
                                                        спречување
                                                        на контаминација во болниците на постелнина, кожа и животна
                                                        средина,
                                                        административни превентивни мерки, и други мерки во согласност
                                                        со
                                                        упатствата на СЗО, а со цел да се намали ризикот на
                                                        здравствените
                                                        работници од изложеност на КОВИД-19 и да се изградат капацитети
                                                        за
                                                        управување со позитивни случаи во случај на овој, и/или следните
                                                        бранови на болеста.
                                                    </p><br/>
                                                    <h5>Regjistrimi për pjesëmarrje në trajnime të punonjësve
                                                        shëndetësor</h5><br/>
                                                    <p className="registrationText">
                                                        Si pjesë e përgjigjes kundër KOVID-19 në Republikën e
                                                        Maqedonisë Veriore OBSH mbështet prezantimin / adaptimin e
                                                        moduleve specifike të trajnimit për KOVID-19, duke përfshirë
                                                        edhe trajnime për parandalimin e transmetimit të agjentëve
                                                        infektues, përdorimin e duhur të paisjeve personale mbrojtëse
                                                        dhe
                                                        parandalimin e ndotjes në shtretërit spitalor , lëkure dhe
                                                        mjedis, masa parandaluese administrative dhe masa të tjera në
                                                        përputhje me udhëzimet e OBSH-së, në mënyrë që të
                                                        zvogëlohet rreziku I punonjësve shëndetësor nga ekspozimi
                                                        ndaj
                                                        COVID-19 dhe të ndertohen kapacitete per të menaxhuar rastet
                                                        pozitive të tanishme, / ose valet e ardhëshme të sëmundjes.
                                                    </p><br/>
                                                    <h5>Registration for health professional training attendance </h5>
                                                    <br/>
                                                    <p className="registrationText">
                                                        As part of the COVID-19 response in North Macedonia WHO is
                                                        supporting introduction / adaptation of specific training
                                                        modules
                                                        about COVID – 19, including trainings about preventing
                                                        transmission
                                                        of infectious agents, correct use of PPE and prevention of
                                                        contamination in the clinic of cloth, skin, and environment,
                                                        administrative preventive measures and others - in line with WHO
                                                        guidelines and with an aim to reduce risk of health care workers
                                                        to
                                                        COVID – 19 exposure and build capacities for case management in
                                                        case
                                                        of this and next waves of the disease.

                                                    </p>
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="footer_reset">

                                   {strings.footer} © {" "} {new Date().getFullYear()} Copyright
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default Login;