import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import Aux from "../../hoc/AuxWrapper";
import { faEdit, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import Select from "react-select";
import { strings } from "../Localization/Localization";

class CrudModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      entity: {},
    };
  }

  componentDidMount() {
    this.setState({
      entity: { ...this.props.entity },
    });
  }

  handlePropsChange = (name, value) => {
    let update = this.state.entity;
    update[name] = value;
    this.setState({ entity: update });
  };

  handleChange = (e) => {
    // debugger
    const target = e.target || e;
    let update = this.state.entity;
    // let imageUpdate = this.state.image
    update[target.name] = target.value;
    this.setState({ entity: update });
    // this.setState({image: imageUpdate});
    if (target.name === "dateFrom") {
      if (update[target.name] === "") {
        update[target.name] = null;
      }
    }
    if (target.name === "dateTo") {
      if (update[target.name] === "") {
        update[target.name] = null;
      }
    }
  };

  handleCategoryChange = (e) => {
    console.log(e);
    let update = this.state.entity;
    let category = update.category;
    category.id = e.value;
    category.nameMk = e.label;
    console.log(category);
    update["category"] = category;
    this.setState({ entity: update });
  };

  handleDateFromChange = (event) => {
    // debugger
    if (this.state.dateTo && this.state.dateFrom != null) {
      this.checkDateValues(event.target.value, this.state.dateTo);
    }
    if (event.target.value === "") {
      this.setState({ dateFrom: null });
    } else {
      this.setState({ dateFrom: event.target.value });
    }

    this.handleChange(event);
    //console.log("dateFrom E", event.target.value);
  };

  handleDateToChange = (event) => {
    //debugger
    if (this.state.dateFrom && this.state.dateTo != null) {
      this.checkDateValues(this.state.dateFrom, event.target.value);
    }
    if (event.target.value === "") {
      this.setState({ dateTo: null });
      // console.log(event.target.value)
    } else {
      this.setState({ dateTo: event.target.value });
    }
    this.handleChange(event);
    //console.log("dateTo", event.target.value);
  };

  checkDateValues(from, to) {
    // console.log("compare");
    let dateFrom = new Date(from);
    let dateTo = new Date(to);
    if (dateFrom > dateTo && dateTo < dateFrom) {
      this.setState({ dateValid: false });
      return toast.error(strings.validDate);
    } else {
      this.setState({ dateValid: true });
    }
  }

  checkImageFormat(imageName) {
    let nameEnd = imageName.split(".")[1];
    if (nameEnd == "jpg" || nameEnd == "jpeg" || nameEnd == "png") {
      this.setState({ imageValid: true });
    } else {
      this.setState({ imageValid: false });
      return toast.error(strings.imageValid);
    }
  }

  imageUploadHandler = (event) => {
    // let imagePath=event.target.value.split('\\')
    // let imageName= imagePath[imagePath.length-1];
    // let imageType=imageName.split('.')[1]
    // let imageContent=event.target.files[0].content
    // let imageName=event.target.value.split('\\')[2]
    // this.checkImageFormat(imageName)
    // this.setState({image: event.target.files[0]})
    // this.setState({imageName: imageName})

    // debugger;
    let entity = this.state.entity;
    entity.image = event.target.files[0];
    this.setState({ entity: entity, image: event.target.files[0] });
    // this.state.image["imageName"] =  event.target.files[0].name
    // this.state.image["imageType"] =  event.target.files[0].type
    // this.setState({entity: update});
    console.log("TUKAAAAAAAAAA");
    // console.log(this.state.entity);
    // this.checkImageFormat(imageName)
    // this.handleChange(event)
    console.log(this.state.image);
  };

  templateUploadHandler = (event) => {
    let entity = this.state.entity;
    entity.template = event.target.files[0];
    this.setState({entity: entity, template: event.target.files[0]});
    console.log(this.state.template);
  }

  show = () => {
    this.setState({
      show: true,
    });
  };

  close = () => {
    this.setState({
      show: false,
    });
    setTimeout(() => {
      location.reload();
    }, 1500);
  };

  handleSubmit = (e) => {
    // debugger;
    e.preventDefault();
    this.props.onSubmit(this.state.entity).then(() => {
      this.close();
    });
  };

  render() {
    const Body = this.props.body;
    return (
      <Aux>
        <Button className={"btn " + this.props.btnClass} onClick={this.show}>
          {this.props.icon === "Add" ? (
            <FontAwesomeIcon
              icon={faPlusCircle}
              style={{ paddingRight: "4px" }}
            />
          ) : null}
          {this.props.icon === "Edit" ? (
            <FontAwesomeIcon icon={faEdit} style={{ paddingRight: "4px" }} />
          ) : null}
          {this.props.title}
        </Button>
        <Modal show={this.state.show} onHide={this.close} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={"container"}>
              <Body entity={this.state.entity}
                    image={this.state.image}
                    template={this.state.template} //template
                    handleChange={this.handleChange}
                    handleCategoryChange={this.handleCategoryChange}
                    handleDateFromChange={this.handleDateFromChange}
                    handleDateToChange={this.handleDateToChange}
                    imageUploadHandler={this.imageUploadHandler}
                    templateUploadHandler={this.templateUploadHandler} //template
                    onSubmit={this.handleSubmit}
                    dateValid={this.state.dateValid}
                    receivedFile={this.props.receivedFile}/>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              id="edit_modal_close_button"
              onClick={this.close}
            >
              {strings.close}
            </Button>
            <Button
              className="btn btn-primary"
              id="edit_modal_save_button"
              type="submit"
              onClick={this.handleSubmit}
            >
              {strings.save}
            </Button>
          </Modal.Footer>
        </Modal>
      </Aux>
    );
  }
}

export default CrudModal;
