import React, { Component } from "react";
import "../../assets/css/theme/base.css";
import "./UserSelectedCourses.css";
import "react-toastify/dist/ReactToastify.css";
import UserCourseRepository from "../../repository/UserCourseRepository";
import { Link } from "react-router-dom";
import { OBUKI } from "../../shared/utility";
import Nav from "../../components/Navbar/Nav";
import moiobuki from "../../assets/images/dashboard_icons/moiobuki.png";
import searchIcon from "../../assets/images/search.svg";
import { Card } from "react-bootstrap";
import img from "./../../assets/images/Slika_Kurs.png";
import "./CourseCard.css";
import Typography from "@material-ui/core/Typography";
import CategoryRepository from "../../repository/CategoryRepository";
import { strings } from "../Localization/Localization";
import { components } from "react-select";
import { default as ReactSelect } from "react-select";
import ReactPaginate from "react-paginate";
import Popper from "@mui/material/Popper";
import { Paper } from "@mui/material";
import { Box } from "@mui/material";
import Loading from "../loading/Loader";
const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <div style={{ display: "flex", justifyContent: "start" }}>
          <input
            className="mt-1"
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />
          <label className="ml-2">{props.label}</label>
          {"  "}
        </div>
      </components.Option>
    </div>
  );
};
const colourStyles = {
  control: (base, state) => ({
    ...base,
    borderRadius: "10px",
    textTransform: "uppercase",
    background: state.isFocused ? "#0061AF" : "#0061AF",
    minWidth: "250px",
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "white",
    display: "flex",
    fontSize: "15px",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    svg: {
      fill: "white",
    },
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected ? "#D6EDFF" : "white",
    color: state.isSelected ? "black" : "black",
    "&:hover": {
      background: state.isSelected ? "#D6EDFF" : "#F9F9F9",
    },
  }),
  menu: (provided, state) => {
    return {
      ...provided,
      zIndex: 9999,
    };
  },
  indicatorSeparator: (base, state) => ({
    ...base,
    display: "none",
  }),
};
class UserSelectedCourses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userCourses: [],
      userCoursesFiltered: [],
      anchorElTitle: null,
      anchorElDesc: null,
      description: null,
      title: null,
      categories: [],
      optionSelected: null,
      loading: false,
      myCoursesFilterDTO: {
        searchString: null,
        categoryIds: null,
      },
    };
  }

  componentDidMount() {
    this.getUserCoursesFiltered();
    this.getCategories();
  }
  truncateTitle = (str) => {
    return str.length > 35 ? str.substring(0, 35) + "..." : str;
  };
  truncateDescription = (str) => {
    return str.length > 90 ? str.substring(0, 90) + "..." : str;
  };
  handlePopoverOpenTitle = (event) => {
    this.setState({
      anchorElTitle: event.currentTarget,
    });
    if (!event.currentTarget.innerText.includes("...")) {
      this.setState({
        anchorElTitle: null,
      });
    }
  };

  handlePopoverCloseTitle = () => {
    this.setState({
      anchorElTitle: null,
    });
  };
  handlePopoverOpenDescription = (event) => {
    this.setState({
      anchorElDesc: event.currentTarget,
    });
    if (!event.currentTarget.innerText.includes("...")) {
      this.setState({
        anchorElDesc: null,
      });
    }
  };

  handlePopoverCloseDescription = () => {
    this.setState({
      anchorElDesc: null,
    });
  };
  setTitle = (item) => {
    this.setState({
      title: item.course.name,
    });
  };
  setDescription = (item) => {
    this.setState({
      description: item.course.description,
    });
  };
  handleChange = (selected) => {
    let listCatIds = [];
    this.setState({
      optionSelected: selected,
    });
    for (var i = 0; i < selected.length; i++) {
      listCatIds.push(selected[i].value);
    }
    this.setState(
      {
        myCoursesFilterDTO: {
          ...this.state.myCoursesFilterDTO,
          categoryIds: listCatIds,
        },
      },
      () => {
        this.getUserCoursesFiltered();
      }
    );
    if (listCatIds.length === 0) {
      this.setState(
        {
          myCoursesFilterDTO: {
            ...this.state.myCoursesFilterDTO,
            categoryIds: null,
          },
        },
        () => {
          this.getUserCoursesFiltered();
        }
      );
    }
  };
  handleChangeSearchString = (event) => {
    if (event.key === "Enter") {
      this.setState(
        {
          myCoursesFilterDTO: {
            ...this.state.myCoursesFilterDTO,
            searchString: event.target.value,
          },
        },
        () => {
          this.getUserCoursesFiltered();
        }
      );
    }
    if (event.target.value === "") {
      this.setState({
        myCoursesFilterDTO: {
          ...this.state.myCoursesFilterDTO,
          searchString: null,
        },
      });
    }
  };
  handleChangeSearch = (event) => {
    this.setState({
      myCoursesFilterDTO: {
        ...this.state.myCoursesFilterDTO,
        searchString: event.target.value,
      },
    });
    if (event.target.value === "") {
      this.setState(
        {
          myCoursesFilterDTO: {
            ...this.state.myCoursesFilterDTO,
            searchString: null,
          },
        },
        () => this.getUserCoursesFiltered()
      );
    }
  };
  clearSearchString = () => {
    this.setState(
      {
        myCoursesFilterDTO: {
          ...this.state.myCoursesFilterDTO,
          searchString: null,
        },
      },
      () => this.getUserCoursesFiltered()
    );
    document.getElementById("searchBar").value = "";
  };
  // getUserCourses = () => {
  //     let email = localStorage.getItem('userEmail');
  //     UserCourseRepository.getUserCourses(email).then(res => {
  //         this.setState({
  //             userCourses: res.data
  //         })
  //     }).catch(() => {
  //         toast.error('Неуспешно')
  //     })
  // };
  getUserCoursesFiltered = (page = 0, size = 12) => {
    let email = localStorage.getItem("userEmail");
    this.setState({
      loading: true,
    });
    UserCourseRepository.getUserCoursesFiltered(
      email,
      page,
      size,
      this.state.myCoursesFilterDTO
    ).then((res) => {
      this.setState({
        loading: false,
      });
      this.setState({
        userCoursesFiltered: res.data.content,
        pageCount: res.data.totalPages,
      });
    });
  };
  getCategories = () => {
    CategoryRepository.getAllCategories().then((res) => {
      this.setState({
        categories: res.data,
      });
    });
  };

  showCategory = (item) => {
    if (item.course.language === "Македонски") {
      return item.course.category.nameMk;
    } else if (item.course.language === "Shqip") {
      return item.course.category.nameAl;
    } else if (item.course.language === "English") {
      return item.course.category.nameEn;
    }
  };
  category = (item) => {
    if (item.course.language === "Македонски") {
      return "Категорија";
    } else if (item.course.language === "Shqip") {
      return "Kategoria";
    } else if (item.course.language === "English") {
      return "Category";
    }
  };
  userHasClickedPre = (courseId) => {
    //debugger;
    UserCourseRepository.setClickedPre(courseId).then((r) =>
      this.getUserCoursesFiltered()
    );
  };

  dateOfCourse = (item) => {
    if (
      [item.course.dateFrom, item.course.dateTo].filter(Boolean).join(" - ")
    ) {
      return [item.course.dateFrom, item.course.dateTo]
        .filter(Boolean)
        .join(" - ");
    } else {
      if (item.course.language === "Македонски") {
        return "Нема датум";
      } else if (item.course.language === "Shqip") {
        return "Nuk ka datë";
      } else if (item.course.language === "English") {
        return "No date";
      }
    }
  };
  courseAccredited = (item) => {
    if (item.course.language === "Македонски") {
      return "Акредитирано со ";
    } else if (item.course.language === "Shqip") {
      return "Akredituar me";
    } else if (item.course.language === "English") {
      return "Accredited with";
    }
  };
  points = (item) => {
    if (item.course.language === "Македонски") {
      return "бода";
    } else if (item.course.language === "Shqip") {
      return "pikë";
    } else if (item.course.language === "English") {
      return "points";
    }
  };

  renderButtons = (item) => {
    return (
      <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
        {item.course.evaluation === "PRE" ? (
          <div>
            {/* {item.clickedPre ?
                            <a className="btn  btn-sm roundEdge  otvoriObukaP" href={item.course.link}
                               target="_blank"
                               rel="noopener noreferrer">Отвори обука</a> :
                            <button className="btn btn-sm roundEdge marginRight otvoriObukaP" disabled>Отвори
                                обука</button>} */}
            <a
              style={{
                color: "white",
                width: "100%",
                whiteSpace: "nowrap",
              }}
              className="btn  btn-sm roundEdge preevaluacija"
              href={item.course.evaluationLinkPre}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => this.userHasClickedPre(item.course.id)}
            >
              {strings.coursePreEvaluation}

              {/* <a
              style={{
                color: "white",
                width: "100%",
                marginBottom: "5px",
                whiteSpace: "nowrap",
              }}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-sm roundEdge preevaluacija"
              href={item.course.evaluationLinkPre}
              onClick={() => this.userHasClickedPre(item.course.id)}
            ></a> */}
            </a>
          </div>
        ) : null}

        {item.course.evaluation === "POST" ? (
          <div>
            {/* <a className="btn roundEdge  btn-sm otvoriObukaP" href={item.course.link}
                            target="_blank"
                            rel="noopener noreferrer">Отвори обука</a> */}
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-sm roundEdge  postevaluacija"
              href={item.course.evaluationLinkPost}
            >
              {strings.coursePostEvaluation}
            </a>
          </div>
        ) : null}
        {item.course.evaluation === "BOTH" ? (
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            {/* {item.clickedPre ?
                            <a className="btn  btn-sm roundEdge  otvoriObukaP" href={item.course.link}
                               target="_blank"
                               rel="noopener noreferrer">Отвори обука</a> :
                            <button className="btn btn-sm roundEdge marginRight otvoriObukaP" disabled>Отвори
                                обука</button>} */}
            <a
              style={{
                color: "white",
                width: "100%",
                marginBottom: "5px",
                whiteSpace: "nowrap",
              }}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-sm roundEdge preevaluacija"
              href={item.course.evaluationLinkPre}
              onClick={() => this.userHasClickedPre(item.course.id)}
            >
              {strings.coursePreEvaluation}
            </a>

            {item.clickedPre ? (
              <a
                style={{
                  color: "white",
                  width: "100%",
                  whiteSpace: "nowrap",
                }}
                target="_blank"
                rel="noopener noreferrer"
                className="btn  btn-sm roundEdge postevaluacija "
                href={item.course.evaluationLinkPost}
              >
                {strings.coursePostEvaluation}
              </a>
            ) : (
              <button
                style={{ color: "white" }}
                className="btn  btn-sm roundEdge postevaluacija"
                disabled
              >
                {strings.coursePostEvaluation}
              </button>
            )}
          </div>
        ) : null}
        {/* {item.course.evaluation === "NOTHING" ? (
          <a
            className="btn btn-sm roundEdge otvoriObukaN"
            href={item.course.link}
            style={{
              color: "white",
              width: "100%",
              marginBottom: "5px",
              whiteSpace: "nowrap",
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Отвори обука
          </a>
        ) : null} */}
      </div>
    );
  };
  handlePageClick = (data) => {
    this.getUserCoursesFiltered(data.selected);
  };
  render() {
    const options = this.state.categories.map((cat) => ({
      value: cat.id,
      label:
        localStorage.getItem("activeLanguage") === "mk"
          ? cat.nameMk
          : localStorage.getItem("activeLanguage") === "al"
          ? cat.nameAl
          : localStorage.getItem("activeLanguage") === "en"
          ? cat.nameEn
          : null,
    }));
    return (
      <>
        <Nav
          title={
            <div>
              {strings.my} <br /> {strings.coursesHeader}
            </div>
          }
          image={moiobuki}
        />
        <div className="container pb-5">
          <p
            className="ml-lg-3 mt-lg-2"
            style={{ color: "black", fontSize: "20px", fontWeight: "500" }}
          >
            {strings.filterBy}
          </p>
          <div
            className="filterDiv pb-3"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <ReactSelect
              placeholder={strings.category}
              className="mt-1 ml-xl-3"
              options={options}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              isSearchable={false}
              styles={colourStyles}
              controlShouldRenderValue={false}
              isClearable={false}
              components={{
                Option,
              }}
              onChange={this.handleChange}
              allowSelectAll={true}
              value={this.state.optionSelected}
            />
            <br class="d-md-none" />
            <div className=" p-1 searchDiv mr-lg-1 mt-lg-1  searchDiv">
              {/* <img
                style={{}}
                src={searchIcon}
                onClick={()=>this.getUserCoursesFiltered()}
              /> */}
              <div style={{ position: "relative" }}>
                <input
                  id="searchBar"
                  style={{
                    border: "1px solid lightgrey",
                    height: "30px",
                    borderRadius: "10px",
                    fontSize: "14px",
                    minWidth: "220px",
                  }}
                  onChange={this.handleChangeSearch}
                  onKeyPress={this.handleChangeSearchString}
                  placeholder={strings.search}
                  type="text"
                />
                {this.state.myCoursesFilterDTO.searchString !== null && (
                  <i
                    style={{
                      webkitTextStroke: "0.5px white",
                      cursor: "pointer",
                      position: "absolute",
                      verticalAlign: "middle",
                      right: "10px",
                      top: 0,
                      height: "100%",
                    }}
                    class="fa fa-times mt-2 ml-1"
                    aria-hidden="true"
                    onClick={this.clearSearchString}
                  ></i>
                )}
              </div>
              <i
                class="fa fa-search ml-2"
                aria-hidden="true"
                style={{ fontSize: "30px", cursor: "pointer" }}
                onClick={() => this.getUserCoursesFiltered()}
              ></i>
            </div>
          </div>
          {!this.state.loading ? (
            <div className="coursesContainer row">
              {this.state.userCoursesFiltered.map((item) => {
                let email = localStorage.getItem("userEmail");
                return (
                  <div className="col-lg-3 d-flex align-items-stretch pt-3">
                    <Card
                      className="box shadow"
                      style={{ width: "100%" }}
                      id={`card-${item.course.id}`}
                    >
                      <div>
                        {item.course.image === null ? (
                          <Card.Img
                            variant="top"
                            src={img}
                            style={{ height: "160px" }}
                          />
                        ) : (
                          <Card.Img
                            variant="top"
                            src={`data:${item.course.imageType};base64,${item.course.image}`}
                            style={{ height: "160px" }}
                          />
                        )}
                      </div>
                      <Card.Body
                        style={{
                          overflow: "hidden",
                          paddingRight: "5px",
                          paddingLeft: "5px",
                        }}
                      >
                        <div>
                          <Card.Title
                            id="courseTitle"
                            style={{ height: "50px" }}
                            onMouseOver={() => this.setTitle(item)}
                            onMouseEnter={this.handlePopoverOpenTitle}
                            onMouseLeave={this.handlePopoverCloseTitle}
                          >
                            {this.truncateTitle(item.course.name)}
                          </Card.Title>
                          <Box>
                            <Popper
                              open={Boolean(this.state.anchorElTitle)}
                              anchorEl={this.state.anchorElTitle}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              transformOrigin={{
                                vertical: "left",
                                horizontal: "left",
                              }}
                              disablePortal
                              onClose={this.handlePopoverCloseTitle}
                              style={{ pointerEvents: "none" }}
                            >
                              <Paper
                                style={{
                                  boxShadow:
                                    "0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.05)",
                                }}
                              >
                                <Typography sx={{ p: 2 }}>
                                  {this.state.title}
                                </Typography>
                              </Paper>
                            </Popper>
                          </Box>
                          <Card.Subtitle
                            className="mb-2"
                            id="courseDescription"
                            onMouseOver={() => this.setDescription(item)}
                            onMouseEnter={this.handlePopoverOpenDescription}
                            onMouseLeave={this.handlePopoverCloseDescription}
                            style={{ overflow: "hidden", height: "100px" }}
                          >
                            {" "}
                            {this.truncateDescription(item.course.description)}
                          </Card.Subtitle>
                          <Box>
                            <Popper
                              open={Boolean(this.state.anchorElDesc)}
                              anchorEl={this.state.anchorElDesc}
                              disablePortal
                              onClose={this.handlePopoverCloseDescription}
                              style={{ pointerEvents: "none" }}
                            >
                              <Paper
                                style={{
                                  boxShadow:
                                    "0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.12)",
                                }}
                              >
                                <Typography sx={{ p: 2 }}>
                                  {this.state.description}
                                </Typography>
                              </Paper>
                            </Popper>
                          </Box>
                        </div>

                        <br />
                        <div>
                          <p>
                            {this.courseAccredited(item)} {item.course.points}{" "}
                            {this.points(item)}
                          </p>
                          <Card.Text
                            id="courseCategory"
                            style={{ height: "100px" }}
                          >
                            <p style={{ fontWeight: "bold" }}>
                              {this.category(item)}:{" "}
                              {"★" + item.course.category
                                ? this.showCategory(item)
                                : "/"}
                            </p>
                          </Card.Text>
                          <div
                            style={{
                              display: "flex",
                              fontSize: "10px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <Card.Text>
                              {"📅" + " " + this.dateOfCourse(item)}
                            </Card.Text>
                            <Card.Text className="pl-3">
                              {"🌐" + " " + item.course.language}
                            </Card.Text>
                          </div>
                        </div>
                      </Card.Body>
                      <div
                        style={{
                          display: "flex",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "50%",
                            paddingRight: "10px",
                          }}
                        >
                          <a
                            className="btn  btn-sm roundEdge bg-primary"
                            id="upatstvo"
                            href={`${OBUKI}/rest/course/upatstvo/${item.course.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {strings.courseManual}
                          </a>
                          {item.course.evaluation === "PRE" ? (
                            <div>
                              {item.clickedPre ? (
                                <a
                                  className="btn  btn-sm roundEdge  otvoriObuka"
                                  href={item.course.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Отвори обука
                                </a>
                              ) : (
                                <button
                                  className="btn btn-sm roundEdge marginRight otvoriObuka"
                                  disabled
                                >
                                  Отвори обука
                                </button>
                              )}
                            </div>
                          ) : null}

                          {item.course.evaluation === "POST" ? (
                            <div>
                              <a
                                className="btn roundEdge  btn-sm otvoriObuka"
                                href={item.course.link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Отвори обука
                              </a>
                            </div>
                          ) : null}
                          {item.course.evaluation === "BOTH" ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                              }}
                            >
                              {item.clickedPre ? (
                                <a
                                  className="btn  btn-sm roundEdge  otvoriObuka"
                                  href={item.course.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Отвори обука
                                </a>
                              ) : (
                                <button
                                  className="btn btn-sm roundEdge marginRight otvoriObuka"
                                  disabled
                                >
                                  Отвори обука
                                </button>
                              )}
                            </div>
                          ) : null}
                          {item.course.evaluation === "NOTHING" ? (
                            <a
                              className="btn btn-sm roundEdge otvoriObuka"
                              href={item.course.link}
                              style={{
                                color: "white",
                                width: "100%",
                                marginBottom: "5px",
                                whiteSpace: "nowrap",
                              }}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Отвори обука
                            </a>
                          ) : null}
                        </div>
                        {this.renderButtons(item)}
                      </div>

                      <div
                        style={{
                          paddingRight: "5px",
                          paddingLeft: "5px",
                          paddingBottom: "5px",
                        }}
                      >
                        {item.course.userUpload ? (
                          <Link
                            id="prikaciSertifikat"
                            to={`/mycourses/${item.course.id}`}
                            className="btn  btn-sm roundEdge bg-warning"
                          >
                            {strings.uploadCertificate}
                          </Link>
                        ) : null}
                        {item.attachment2Id !== null ? (
                          <a
                            style={{
                              background: "#F94F4F",
                              color: "white",
                              width: "100%",
                              marginTop: "5px",
                            }}
                            href={`${OBUKI}/rest/attachment/download/content2?email=${email}&courseId=${item.course.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-sm roundEdge  prevzemiSertifikat"
                          >
                            {strings.downloadCertificate}
                          </a>
                        ) : (
                          <button
                            type="button"
                            disabled={true}
                            className="btn  btn-sm roundEdge mb-1 prevzemiSertifikat "
                          >
                            {strings.downloadCertificate}
                          </button>
                        )}
                      </div>
                    </Card>
                  </div>
                );
              })}
            </div>
          ) : (
            <Loading />
          )}
          <br />

          <div className="ml-3 mt-3">
            <ReactPaginate
              id="myCoursesPag"
              previousLabel={strings.previous}
              nextLabel={strings.next}
              breakLabel={"..."}
              pageCount={this.state.pageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={this.handlePageClick}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </>
    );
  }
}

export default UserSelectedCourses;
