import React, {Component} from 'react';
import '../../assets/css/theme/login-6.css'
import AuthenticationService from "../../repository/service/AuthenticationService";
import {AUTH_TOKEN} from "../../shared/utility";
import Background from '../../assets/images/bg-image.png'
import axios from "../../axios/app";
import "./Registration.css";
import UserRepository from "../../repository/UserRepository";
import {toast} from "react-toastify";
import Swal from 'sweetalert2';
import success from "../../assets/images/success.svg";
import { NavLink } from 'react-router-dom';
import { strings } from '../Localization/Localization';

class Registration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            institution: '',
            employedAs: '',
            email: '',
            password: '',
            mobileNo: '',
            licenceNo: '',
            age:0,
            specialty:'',
            loginError: null,
            role: "",
            licenceNoVisible: false,
            show: false,
            
        }
    }

  
    fetchRole() {
        axios.get("/rest/user/userDetails").then((data) => {
            localStorage.setItem('userEmail', data.data[0]);
            localStorage.setItem('userDetails', data.data[1]);
            this.props.history.push('/');
        });
    };

    loginHandler(e) {
        e.preventDefault();
        AuthenticationService.loginUser({
            email: this.state.username,
            password: this.state.password
        }).then(response => {
            localStorage.setItem(AUTH_TOKEN, response.data);
            this.clearErrorMessage();
            this.fetchRole();
        }).catch(error => {
            this.setErrorMessage(error.response);
            // alert(error.response.data);
        });
    }

    firstNameChangeHandler(e) {
        this.setState({
            firstName: e.target.value
        });
    }

    lastNameChangeHandler(e) {
        this.setState({
            lastName: e.target.value
        });
    }

    ageChangeHandler(e) {
        this.setState({
            age: e.target.value
        });
    }

    institutionChangeHandler(e) {
        this.setState({
            institution: e.target.value
        });
    }

    employedAsChangeHandler(e) {
        if (e.target.value === "Доктор") {
            this.setState({
                employedAs: e.target.value,
                show: true
            });
        } else {
            this.setState({
                employedAs: e.target.value,
                show: false
            });
        }
    }

    licenceNoChangeHandler(e) {
        this.setState({
            licenceNo: e.target.value
        });
    }

    specialtyChangeHandler(e) {
        this.setState({
            specialty: e.target.value
        });
    }

    mobileNoChangeHandler(e) {
        this.setState({
            mobileNo: e.target.value
        });
    }

    emailChangeHandler(e) {
        this.setState({
            email: e.target.value
        });
    }

    passwordChangeHandler(e) {
        this.setState({
            password: e.target.value
        });
    }

    clearErrorMessage() {
        this.setState({
            loginError: null
        });
    }

    setErrorMessage(statusCode) {
        if (statusCode === 403) {
            this.setState({
                loginError: 'Email or password are incorrect'
            });
        } else {
            this.setState({
                loginError: 'Server temporarily unavailable'
            });
        }
    }

    setLicenceNoVisible = () => {
        this.setState({
            licenceNoVisible: true
        });
    };

    setLicenceNoHidden = () => {
        this.setState({
            licenceNoVisible: false
        });
    };



    registerUser = (e) => {
        e.preventDefault();
        localStorage.setItem('email', this.state.email);
        if (this.state.employedAs!=="Доктор"){
            if(this.state.age<100 && this.state.firstName!=="" && this.state.lastName!=="" && this.state.email!=="" && this.state.password!=="" && this.state.mobileNo!==""
                && this.state.institution!=="" && this.state.employedAs!=="" && this.state.specialty!=="" && this.state.age!=="") {
                const obj = {};
                obj.firstName = this.state.firstName;
                obj.lastName = this.state.lastName;
                obj.email = this.state.email;
                obj.password = this.state.password;
                obj.phone = this.state.mobileNo;
                obj.institution = this.state.institution;
                obj.workingPosition = this.state.employedAs;
                obj.licenseNumber = this.state.licenceNo;
                obj.specialty = this.state.specialty;
                obj.age = this.state.age;

                UserRepository.registerUser(obj).then(() => {
                Swal.fire({
                    iconHtml: `<img src=${success}/>`,
                    title: `${strings.successfullRegister}`,
                    text: `${strings.successfullRegisterText}`,
                    
                  }).then(()=>{
                    this.props.history.push("/login")

                  })
                  
                  
        
                }).catch(() => {
                    toast.error(`${strings.failRegistration}`)
                })
            }else{
                toast.error(`${strings.fillAllFields}`)
            }
        }else{
            if(this.state.age<100 && this.state.firstName!=="" && this.state.lastName!=="" && this.state.email!=="" && this.state.password!=="" && this.state.mobileNo!==""
                && this.state.institution!=="" && this.state.employedAs!=="" && this.state.specialty!=="" && this.state.age!=="" && this.state.licenceNo!=="") {
                const obj = {};
                obj.firstName = this.state.firstName;
                obj.lastName = this.state.lastName;
                obj.email = this.state.email;
                obj.password = this.state.password;
                obj.phone = this.state.mobileNo;
                obj.institution = this.state.institution;
                obj.workingPosition = this.state.employedAs;
                obj.licenseNumber = this.state.licenceNo;
                obj.specialty = this.state.specialty;
                obj.age = this.state.age;

                UserRepository.registerUser(obj).then(() => {
                    Swal.fire({
                        iconHtml: `<img src=${success}/>`,
                        title: `${strings.successfullRegister}`,
                        text: `${strings.successfullRegisterText}`,
                        
                      }).then(()=>{
                        this.props.history.push("/login")
    
                      })
                }).catch(() => {
                    toast.error(`${strings.failRegistration}`)
                })
            }else{
                toast.error(`${strings.fillAllFields}`)
            }
        }

    };

    goBackToLogin = (path) => {
        this.props.history.push(path);
    };
   



    render() {
        return (
          
            <div className="kt-grid kt-grid--ver kt-grid--root kt-page kt-grid-page">
                <div className="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v6 kt-login--signin"
                     id="kt_login">
                    <div
                        className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
                        <div
                            className="kt-grid__item  kt-grid__item--order-tablet-and-mobile-2  kt-grid kt-grid--hor kt-login__aside">
                            <div className="kt-login__wrapper">
                                <div className="kt-login__container">
                                    <div className="kt-login__body">
                                        <div className="kt-login__logo">
                                            <a href="!#">
                                                <img src={require('../../assets/images/MZ.png')}
                                                     className={"img-responsive p-0"} style={{width: "100%"}} alt=""/>
                                            </a>
                                        </div>
                                        <div className='shadow bg-white p-3 mb-5 rounded'>
                                        <div className="kt-login__logo">
                                            <a href="!#">
                                                <img src={require('../../assets/images/cropped-cropped-e-health.jpg')}
                                                     className={"img-responsive"} style={{width: "50%"}} alt=""/>

                                            </a>
                                        </div>
                                        <div className="kt-login__signin">
                                               <div className="kt-login__head row container">
                                                   <NavLink className=" col "  id="login" to="/login">{strings.login}</NavLink>
                                                   <NavLink className="col"  id="signup" to="/registration">{strings.register}</NavLink>
                                               
                                            </div>

                                            <div className="kt-login__form">
                                                <form className="kt-form" onSubmit={(e) => this.registerUser(e)}>
                                                    <div className="form-group">
                                                        <label className='labelRegister'>{strings.name}</label>
                                                        <input className="form-control" type="text" 
                                                               required={true}
                                                               onChange={(e) => this.firstNameChangeHandler(e)}
                                                               name="firstName" autoComplete="off"/>
                                                    </div>
                                                    <br/>
                                                    <div className="form-group">
                                                    <label className='labelRegister'>{strings.lastName}</label>
                                                        <input className="form-control" type="text"
                                                                required={true}
                                                               onChange={(e) => this.lastNameChangeHandler(e)}
                                                               name="lastName" autoComplete="off"/>
                                                    </div>
                                                    <br/>
                                                    <div className="form-group">
                                                        <label className='labelRegister' >{strings.age}</label>
                                                        <input className="form-control" type="number"
                                                               required={true}
                                                               onChange={(e) => this.ageChangeHandler(e)}
                                                               name="age" autoComplete="off"/>
                                                    </div>
                                                    <br/>
                                                    <div className="form-group">
                                                        <label className='labelRegister'>{strings.institution}</label>
                                                        <input className="form-control" type="text"
                                                               required={true}
                                                               onChange={(e) => this.institutionChangeHandler(e)}
                                                               name="institution" autoComplete="off"/>
                                                    </div>
                                                    <br/>
                                                    <div className="form-group">
                                                    <label className='labelRegister'>{strings.workPosition}</label>
                                                        <select className="form-control" type="dropdown"
                                                                style={{paddingBottom: 10}} required={true}
                                                                onChange={(e) => this.employedAsChangeHandler(e)}
                                                                name="employedAs" autoComplete="off">
                                                            <option value="">{}
                                                              
                                                            </option>
                                                            <option value="Доктор"
                                                            >{strings.doctorOfMedicine}
                                                            </option>
                                                            <option value="Стоматолог"
                                                            >{strings.doctorOfDentalMedicine}
                                                            </option>
                                                            <option value="Сестра"
                                                            >{strings.nurse}
                                                            </option>
                                                            <option value="Соработник"
                                                            >{strings.healthAssociate}
                                                            </option>
                                                            <option value="Друго"
                                                            >{strings.other}
                                                            </option>

                                                        </select>
                                                    </div>
                                                 
                                                    {this.state.show && <div className="form-group">
                                                        <br/>
                                                        <label className='labelRegister'>{strings.licenseNumber}</label>
                                                        <input className="form-control" type="number"
                                                               required={true}
                                                               onChange={(e) => this.licenceNoChangeHandler(e)}
                                                               name="licenceNo" autoComplete="off"/>
                                                    </div>}
                                                    <br/>
                                                    <div className="form-group">
                                                    <label className='labelRegister'>{strings.specialty}</label>
                                                        <input className="form-control" type="text"
                                                               required={true}
                                                               onChange={(e) => this.specialtyChangeHandler(e)}
                                                               name="specialty" autoComplete="off"/>
                                                    </div>
                                                    <br/>
                                                    <div className="form-group">
                                                    <label className='labelRegister'>{strings.telephoneNumber}</label>
                                                        <input className="form-control" type="number"
                                                               required={true}
                                                               onChange={(e) => this.mobileNoChangeHandler(e)}
                                                               name="mobileNo" autoComplete="off"/>
                                                    </div>
                                                    <br/>
                                                    <div className="form-group">
                                                        <label className='labelRegister'>{strings.email}</label>
                                                        <input className="form-control" type="text"
                                                               required={true}
                                                               onChange={(e) => this.emailChangeHandler(e)} name="email"
                                                               autoComplete="off"/>
                                                    </div>
                                                    <br/>
                                                    <div className="form-group">
                                                    <label className='labelRegister'>{strings.password}</label>
                                                        <input type="password"
                                                               onChange={(e) => this.passwordChangeHandler(e)} required={true}
                                                               className="form-control form-control-last"
                                                                name="password"/>
                                                    </div>
                                                    <br/>
                                                    <div className="kt-login__actions">
                                                        <button type="submit" id="kt_login_signin_submit"
                                                                className="btn btn-brand btn-pill btn-elevate"
                                                               
                                                                onClick={this.registerUser}>{strings.register}
                                                        </button>

                                                    </div>
                                                        <div style={{display:"flex",justifyContent:"center"}}>{strings.haveAccount} &nbsp; &nbsp; <a href="/login" id="backToLogin">{strings.login}</a></div>
                                                </form>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <footer>
                                <div className="row">
                                    <div className="col-4"></div>
                                    <div className="col-4">
                                        <div className="row">
                                            <p style={{position:"relative",top:"30px",color:"grey",left:"75px",fontSize:"14px"}}>{strings.supportedBy}</p>
                                        </div>
                                        <div className="row">
                                            <img src={require('../../assets/images/usaid3.png')}
                                                 className={"img-responsive"} style={{width: "100%"}} alt=""/>
                                        </div>
                                    </div>
                                    <div className="col-4"></div>
                                </div>
                            </footer>

                            </div>
                        </div>
                        <div
                            className="kt-grid__item kt-grid__item--fluid kt-grid__item--center kt-grid kt-grid--ver kt-login__content"
                            style={{backgroundImage: "url(" + Background + ")"}}>
                            <div className="kt-login__section">
                                <div className="kt-login__block">
                                    <div className="kt-login__desc">
                                        <div className='registrationContainer' >
                                        <div className="registrationTextDiv registerBox" >
                                                <h5>Регистрација за посета на обуки за здравствени работници</h5><br/>
                                                <p className="registrationText">
                                                    Како дел од одговорот против КОВИД-19 во Република Северна
                                                    Македонија, СЗО поддржува воведување / адаптација на специфични
                                                    модули за обука за KОВИД - 19, вклучително и обуки за спречување
                                                    пренесување на заразни агенси, правилна употреба на ЛЗО и спречување
                                                    на контаминација во болниците на постелнина, кожа и животна средина,
                                                    административни превентивни мерки, и други мерки во согласност со
                                                    упатствата на СЗО, а со цел да се намали ризикот на здравствените
                                                    работници од изложеност на КОВИД-19 и да се изградат капацитети за
                                                    управување со позитивни случаи во случај на овој, и/или следните
                                                    бранови на болеста.
                                                </p><br/>
                                                <h5>Regjistrimi për pjesëmarrje në trajnime të punonjësve
                                                    shëndetësor</h5><br/>
                                                <p className="registrationText">
                                                    Si pjesë e përgjigjes kundër KOVID-19 në Republikën e
                                                    Maqedonisë Veriore OBSH mbështet prezantimin / adaptimin e
                                                    moduleve specifike të trajnimit për KOVID-19, duke përfshirë
                                                    edhe trajnime për parandalimin e transmetimit të agjentëve
                                                    infektues, përdorimin e duhur të paisjeve personale mbrojtëse dhe
                                                    parandalimin e ndotjes në shtretërit spitalor , lëkure dhe
                                                    mjedis, masa parandaluese administrative dhe masa të tjera në
                                                    përputhje me udhëzimet e OBSH-së, në mënyrë që të
                                                    zvogëlohet rreziku I punonjësve shëndetësor nga ekspozimi ndaj
                                                    COVID-19 dhe të ndertohen kapacitete per të menaxhuar rastet
                                                    pozitive të tanishme, / ose valet e ardhëshme të sëmundjes.
                                                </p><br/>
                                                <h5>Registration for health professional training attendance </h5><br/>
                                                <p className="registrationText">
                                                    As part of the COVID-19 response in North Macedonia WHO is
                                                    supporting introduction / adaptation of specific training modules
                                                    about COVID – 19, including trainings about preventing transmission
                                                    of infectious agents, correct use of PPE and prevention of
                                                    contamination in the clinic of cloth, skin, and environment,
                                                    administrative preventive measures and others - in line with WHO
                                                    guidelines and with an aim to reduce risk of health care workers to
                                                    COVID – 19 exposure and build capacities for case management in case
                                                    of this and next waves of the disease.
                                                </p>
                                            </div>
                                      
                                        </div>
                                    </div>
                                  
                                </div>
                               
                                <div className="footer_reset">
                                       {strings.footer} © {" "} {new Date().getFullYear()} Copyright
                                        </div>
                            </div> 
                        </div>
                    </div>
                </div>
             
              
            </div>
       

           

        );
    }
}


export default Registration;