import React, {Component} from "react";
import coursesRepository from "../../repository/CategoryRepository";
import {toast} from "react-toastify";
import categoryRepository from "../../repository/CategoryRepository";
import {strings} from "../Localization/Localization";
import classes from './Category.css';

class AddCategory extends Component{

    constructor(props) {
        super(props);
        this.state = {
            nameMk: "",
            nameAl: "",
            nameEn: ""
        }
    }

    nameChangeHandler = (event) => {
        this.setState({[event.target.name]: event.target.value})
    };

    addCategory = (e) => {
        e.preventDefault();
        const form = new FormData();
        if(this.state.nameMk === '' && this.state.nameAl === '' && this.state.nameEn === '')
            return toast.error(strings.errorCategoryMessages);
        if (this.state.nameMk !== '') {
            form.append("nameMk", this.state.nameMk);
            form.append("nameAl", this.state.nameAl);
            form.append("nameEn", this.state.nameEn);
            categoryRepository.addCategory({nameMk: this.state.nameMk, nameAl: this.state.nameAl, nameEn: this.state.nameEn})
                .then(() => {
                    toast.success(strings.successfullyAddedCategory)
                    this.props.history.push("/category");
                })
                .catch(() => toast.error(strings.errorCategoryMessage))
        } else return toast.error(strings.errorCategoryMessages);
    }

    render() {
        return (
            <div className="container mt-3">
                <form>
                    <div className="form-group">
                        <label>{strings.macedonianName}</label>
                        <input placeholder={strings.requiredField} name="nameMk" id="nameMkInput"  type="text" required minLength="1" onChange={this.nameChangeHandler} className="form-control"/>
                        <label>{strings.albanianName}</label>
                        <input name="nameAl" type="text" onChange={this.nameChangeHandler} className="form-control"/>
                        <label>{strings.englishName}</label>
                        <input name="nameEn" type="text" onChange={this.nameChangeHandler} className="form-control"/>
                    </div>
                    <div className="form-group">
                        <button type="submit" onClick={this.addCategory} className="btn btn-info btn-sm button_category">{strings.add}</button>
                    </div>
                </form>
            </div>
        )
    }
}

export default AddCategory;