import React, {Component} from "react";
import coursesRepository from "../../repository/CoursesRepository";
import {toast} from "react-toastify";
import CategoryRepository from "../../repository/CategoryRepository";
import Select from 'react-select';
import {strings} from "../Localization/Localization";

class AddCourse extends Component {

    constructor(props) {
        super(props);

        // let today = new Date(),
        //     month= (today.getMonth() + 1)<10? '0'+(today.getMonth() + 1 ): (today.getMonth()).toString() ,
        //     day =(today.getDate())<10? '0'+today.getDate()  : (today.getDate()).toString(),
        //     date = today.getFullYear() + '-' +(month) + '-' + day;



        this.state = {
            name: "",
            desc: "",
            link: "",
            status: "",
            userUpload: "",
            evaluation: "",
            points: null,
            file: {},
            fileName:"Изберете Фајл",
            evaluationLinkPost: "",
            evaluationLinkPre: "",
            categories: [],
            categoryName: strings.courseCategoryChoose,
            category: null,
            language: "",
            dateFrom: null,
            dateTo: null,
            image: null,
            imageName:"",
            dateValid: true,
            imageValid:true,
            template: null,
            templateName: "",
            templateValid: true
        }
    }
    componentDidMount() {
        this.getCategories();

    }
    getCategories = () => {
        CategoryRepository.getAllCategories().then(res => {
            this.setState({
                categories: res.data
            })
        })
    }

    evaluationPostChangeHandler = (event) => {
        this.setState({evaluationLinkPost: event.target.value})
    };
    evaluationPreChangeHandler = (event) => {
        this.setState({evaluationLinkPre: event.target.value})
    };
    evaluationChangeHandler = (event) => {
        this.setState({evaluation: event.target.value})
    };

    nameChangeHandler = (event) => {
        this.setState({name: event.target.value})
    };
    descChangeHandler = (event) => {
        this.setState({desc: event.target.value})
    };
    linkChangeHandler = (event) => {
        this.setState({link: event.target.value})
    };
    statusChangeHandler = (event) => {
        this.setState({status: event.target.value})
    };
    userUploadChangeHandler = (event) => {
        this.setState({userUpload: event.target.value})
    };
    onUploadHandler = (event) => {
        let imagePath=event.target.value.split('\\')
        let fileName= imagePath[imagePath.length-1]
        this.setState({file: event.target.files[0]})
        this.setState({fileName: fileName})
    };
    imageUploadHandler = (event) => {
        let imagePath=event.target.value.split('\\')
        let imageName= imagePath[imagePath.length-1]
        if (this.checkImageFormat(event.target.files[0])){
            this.setState({image: event.target.files[0]})
            this.setState({imageName: imageName})
        }
        else{
            this.setState({image:null})
            this.setState({imageName: ""})
        }

    };
    pointsChangeHandler = (event) => {
        this.setState({points: event.target.value})
    };
    categoryChangeHandler = (event) => {
        console.log(event)
        this.setState({category: event.value});
        this.setState({categoryName: event.label});
    };
    languageChangeHandler = (event) => {
        this.setState({language: event.target.value})
    };
    dateFromChangeHandler = (event) => {
        if(this.state.dateTo!= null){
            this.checkDateValues(event.target.value,this.state.dateTo);
        }
        this.setState({dateFrom: event.target.value})
    };
    dateToChangeHandler = (event) => {
        if(this.state.dateFrom!= null){
            this.checkDateValues(this.state.dateFrom,event.target.value);
        }
        this.setState({dateTo:event.target.value})
    };
    checkDateValues(from,to) {
        let dateFrom =new Date(from);
        let dateTo = new Date(to);
        if(dateFrom>dateTo){
            this.setState({dateValid: false})
        }
        else{this.setState({dateValid: true})}


    }
    checkImageFormat(image){
        console.log(image)
        if (image==null){
            return true;
        }
        let nameEnd= image.name.split('.')[1]
        if(nameEnd == "jpg" || nameEnd == "jpeg" || nameEnd == "png"){
            this.setState({imageValid: true});
            return true;
        }
        else{
            this.setState({imageValid: false});
            console.log("false");
            return false;
        }
    }

    templateUploadHandler = (event) => {
        // debugger
        let templatePath = event.target.value.split('\\')
        let templateName= templatePath[templatePath.length-1]
        if(this.checkTemplateFormat(event.target.files[0])){
            this.setState({template: event.target.files[0]})
            this.setState({templateName: templateName})
        }
        else{
            this.setState({template: null})
            this.setState({templateName: ""})
        }
    }

    checkTemplateFormat(template){
        // debugger
        console.log(template)
        if(template==null){
            return true;
        }
        let nameEnd = template.name.split('.')[1]
        if(nameEnd == "docx"){
            this.setState({templateValid: true});
            return true;
        }
        else{
            this.setState({templateValid: false});
            return false;
        }
    }

    // checkImageValid(){
    //     if(this.state.image =={}){
    //         return true;
    //     }
    //     else if(this.state.imageValid == true){
    //         return true;
    //     }
    //     else{
    //         return false;
    //     }
    // }
    selectStyles = {
        control: (provided,state) => ({
            ...provided,
            height:"calc(1.5em + 1.3rem + 2px)",
            fontSize: "1rem",
            borderRadius: "20px",
            fontWeight: "400",
            lineHeight:"1.5",
            paddingLeft: "5px ",
            boxShadow: "rgb(0 0 0 / 15%) 0px 5px 15px",
            color:"black  !important",
            backgroundColor:"#fff",
            backgroundClip: "padding-box",
            fontFamily: "sans-serif",
            border:" 1px solid #e2e5ec",

        }),
        indicatorSeparator: (provided,state) => ({

        }),
        menu:(provided) => ({
            ...provided,
            border:"1px solid #black !important",
            boxShadow: "rgb(0 0 0 / 15%) 0px 5px 15px",
            height:"3rem !important"
        }),

        option: (provided, state) => ({
            ...provided,
            color: state.isFocused ? "white" : "#495057",
            backgroundColor: state.isFocused ? "#1e90ff" : "white",
            fontFamily: "sans-serif",
            fontSize: "1rem",
            padding: "0",
            paddingLeft: "15px ",
            "&:hover": {
                color: "white"
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: " #495057",
        }),
        singleValue: (provided) => ({
            ...provided,
            color: "#495057",
            paddingLeft: "15px ",
            fontFamily: "sans-serif",
            lineHeight:"1.5",
            "&:hover":{
                color: "white",
                backgroundColor:"#blue !important",
            },

        })
    }

    addCourse = (e) => {
        e.preventDefault();
        const form = new FormData();
        if (this.state.name !== '' && this.state.desc !== '' && this.state.link !== '' && this.state.status !== '' && (this.state.points < 5 && this.state.points >= 0)
            && this.state.category !== null && this.state.language !== '' && this.state.dateValid !== false && this.checkImageFormat(this.state.image)
            && this.checkTemplateFormat(this.state.template)) {
            console.log("OK")
            form.append("description", this.state.desc);
            form.append("name", this.state.name);
            form.append("link", this.state.link);
            form.append("evaluationLinkPre", this.state.evaluationLinkPre);
            form.append("evaluationLinkPost", this.state.evaluationLinkPost);
            form.append("points", this.state.points);
            form.append("userUpload", this.state.userUpload);
            form.append("evaluation", this.state.evaluation);
            form.append("upatstvo", this.state.file);
            form.append("categoryId", this.state.category);
            form.append("status", this.state.status);
            form.append("image", this.state.image);
            form.append("language", this.state.language);
            form.append("template", this.state.template);
            if (this.state.dateFrom) {
                form.append("dateFrom", this.state.dateFrom);
            } else if(this.state.dateFrom == null && this.state.dateTo != null){
                return toast.error(strings.toastOneDateFail)
            }

            if (this.state.dateTo) {
                form.append("dateTo", this.state.dateTo);
            } else if(this.state.dateTo == null && this.state.dateFrom != null){
                return toast.error(strings.toastOneDateFail)
            }

            for(var pair of form.entries()) {
                console.log(pair[0]+ ', '+ pair[1]);
            }
            coursesRepository.addCourse(form)
                .then(() => {
                    toast.success(strings.toastSuccess)
                    this.props.history.push("/");
                })
                .catch(error=> {
                    if(error.message="Request failed with status code 400"){
                        return toast.error(strings.toastOneDateFail);
                    }
                });
        }
        else{
            toast.error(strings.toastFail)
        }
    }

    render() {

        return (
            <div className="container mt-3">
                <form>
                    <div className="form-group">
                        <label>{strings.name}</label>
                        <input type="text" onChange={this.nameChangeHandler} className="form-control"/>
                    </div>
                    <div className="form-group">
                        <label>{strings.courseDescription}</label>
                        <input type="text" onChange={this.descChangeHandler} className="form-control"/>
                    </div>
                    <div className="form-group">
                        <label>{strings.courseLink}</label>
                        <input type="text" onChange={this.linkChangeHandler} className="form-control"/>
                    </div>
                    <div className="form-group">
                        <label>{strings.courseStatus}</label>
                        <select required={true} className="form-control custom-select"
                                onChange={this.statusChangeHandler}>
                            <option>{strings.courseStatusOptionChoose}</option>
                            <option value="true">{strings.courseStatusOptionActive}</option>
                            <option value="false">{strings.courseStatusOptionInactive}</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label>{strings.courseCertificate}</label>
                        <select required={true} className="form-control custom-select"
                                onChange={this.userUploadChangeHandler}>
                            <option>{strings.courseCertificateAddChoose}</option>
                            <option value="true">{strings.courseCertificateAddCanUpload}</option>
                            <option value="false">{strings.courseCertificateAddCannotUpload}</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label>{strings.courseEvaluation}</label>
                        <select required={true} className="form-control custom-select"
                                onChange={this.evaluationChangeHandler}>
                            <option>{strings.courseEvaluationType}</option>
                            <option value="PRE">{strings.evaluationPre}</option>
                            <option value="POST">{strings.evaluationPost}</option>
                            <option value="BOTH">{strings.evaluationBoth}</option>
                            <option value="NOTHING">{strings.evaluationNothing}</option>
                        </select>
                    </div>
                    <div className="form-group">
                        {this.state.evaluation === "PRE" ?
                            <div><label>{strings.coursePreEvaluation}</label><input onChange={this.evaluationPreChangeHandler}
                                                                     type="text" className="form-control"/>
                            </div> : null}
                        {this.state.evaluation === "POST" ?
                            <div><label>{strings.coursePostEvaluation}</label><input onChange={this.evaluationPostChangeHandler}
                                                                      type="text" className="form-control"/>
                            </div> : null}
                        {this.state.evaluation === "BOTH" ? <div>
                            <label>{strings.coursePreEvaluation}</label>
                            <input onChange={this.evaluationPreChangeHandler} type="text" className="form-control"/>
                            <br/>
                            <label>{strings.coursePostEvaluation}</label>
                            <input onChange={this.evaluationPostChangeHandler} type="text" className="form-control"/>
                        </div> : null}
                    </div>

                    <div className="form-group">
                        <label>{strings.coursePoints}</label>
                        <input type="number" min={1} max={5} onChange={this.pointsChangeHandler}
                               className="form-control"/>
                    </div>
                    <div className="form-group">
                        <label>{strings.category}</label>
                        <Select
                            closeMenuOnSelect={true}
                            styles={this.selectStyles}
                            isSearchable={true}
                            placeholder={this.state.categoryName}
                            required={true}
                            options={
                                this.state.categories.map((item) => (
                                    {value:item.id,label:item.nameMk}
                                ))}
                            components={{ IndicatorSeparator:() => null }}
                            maxMenuHeight={200}
                            value={this.state.category}
                            onChange={this.categoryChangeHandler}
                        />
                    </div>
                    <div className="form-group">
                        <label>{strings.courseLanguage}</label>
                        <select required={true} className="form-control custom-select"
                                onChange={this.languageChangeHandler}>
                            <option>{strings.courseLanguageChoose}</option>
                            <option value="Македонски">{strings.courseLanguageMk}</option>
                            <option value="Shqip">{strings.courseLanguageAl}</option>
                            <option value="English">{strings.courseLanguageEn}</option>
                        </select>
                    </div>

                    <div className='row'>
                        <div className='col-6'>
                            <div className="form-group">
                                <label>{strings.dateFrom}</label><br/>
                                <input required={false} type="date" className="form-control" name="trip-start"
                                       onChange={this.dateFromChangeHandler}/>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className="form-group">
                                <label>{strings.dateTo}</label><br/>
                                <input required={false} type="date" className="form-control" name="trip-start"
                                       onChange={this.dateToChangeHandler}/>
                            </div>
                        </div>
                    </div>
                    {this.state.dateValid? null:<div className="alert alert-danger" role="alert"> {strings.validDate}</div>}

                    <div className="form-group">
                        <label>{strings.courseManual}</label><br/>
                        <input type="file" className="form-control" onChange={this.onUploadHandler}/>
                    </div>
                    <div className="form-group">
                        <label>{strings.courseImage}</label><br/>
                        <input type="file" className="form-control"  accept=".jpg, .jpeg, .png" onChange={this.imageUploadHandler}/>
                        <label style={{paddingTop: "5px", color: "red"}}>{strings.allowedImageFormats}</label>
                        {this.state.imageValid? null:<div className="alert alert-danger" role="alert"> {strings.invalidImage}</div>}
                    </div>
                    <div className="form-group">
                        <label>{strings.template}</label><br/>
                        <input type="file" className="form-control"  accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={this.templateUploadHandler}/>
                        <label style={{paddingTop: "5px", color: "red"}}>{strings.allowedTemplateFormat}</label>
                        {this.state.templateValid? null:<div className="alert alert-danger" role="alert"> {strings.invalidTemplate} </div>}
                    </div>


                    {/*<div className="form-group">*/}
                    {/*    <label >Упатство</label><br/>*/}
                    {/*    <div className="row">*/}
                    {/*        <div  className="col-4">*/}
                    {/*        <button  className="form-control"  disabled="disabled">*/}
                    {/*            <input type="file" style={{opacity:'0'}} onChange={this.onUploadHandler}/>*/}
                    {/*        </button>*/}
                    {/*        </div>*/}
                    {/*        <div  className="col-8">*/}
                    {/*            <br/>*/}
                    {/*            {this.state.fileName}*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*</div>*/}


                    {/*<div className="form-group">*/}
                    {/*    <label>Слика</label>*/}
                    {/*    <input type="file" className="form-control-file border rounded-pill" onChange={this.imageUploadHandler} accept=".jpg, .jpeg, .png"/>*/}
                    {/*</div>*/}

                    {/*<div className="btn btn-primary rounded-pill">*/}
                    {/*    <span className="rounded-pill">Додади Слика</span>*/}
                    {/*    <input type="file" className="rounded-pill"*/}
                    {/*           style={{ opacity:"0",  width:"100px"}}/>*/}
                    {/*</div>*/}


                    {/*<div className="form-group">*/}
                    {/*    <label>Слика</label>*/}
                    {/*    <div className="input-group ">*/}
                    {/*        <div className="custom-file" >*/}
                    {/*            <input type="file" className="custom-file-input"  onChange={this.imageUploadHandler} accept=".jpg, .jpeg, .png"/>*/}
                    {/*            <label className="custom-file-label rounded-pill shadow"  htmlFor="inputGroupFile01">Изберете слика</label>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}


                    <div className="form-group">
                        <button type="submit" onClick={this.addCourse} className="btn btn-info btn-sm" >{strings.addButton}</button>
                    </div>
                </form>

            </div>
        )
    }
}

export default AddCourse;
