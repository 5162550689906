import React, {Component} from 'react';
import {NavLink, Link} from 'react-router-dom';
import '../../assets/css/theme/base.css'
import navbarMenu from '../../assets/images/navbarMenu.png'
import UserRepository from '../../repository/UserRepository';
import './Navbar.css';
import userIcon from '../../assets/images/userIcon.png'
import {strings} from "../Localization/Localization";
import './NavButton.css';

class Nav extends Component {

    constructor(props) {
        super(props);

        this.state = {
            userEmail: localStorage.getItem('userEmail'),
            userRole: localStorage.getItem('userDetails'),
            menuShown: false,
            active: 'Dashboard',
            firstName:"",
            lastName:"",
            title:this.props.title,
            image:this.props.image,
        }
    }

    toggleSidebar() {
        let menuClass = '';
        let toggleClass = '';

        if (this.state.menuShown) {
            menuClass = 'menu';
            toggleClass = '';
        } else {
            menuClass = 'menu menu_shown';
            toggleClass = 'active'
        }

        document.getElementById('menu').setAttribute('class', menuClass);
        document.getElementById('nav-toggle').setAttribute('class', toggleClass);

        this.setState((prevState) => ({
            menuShown: !prevState.menuShown
        }));
    }

    _onSetLanguageToMacedonian = () =>{
        strings.setLanguage('mk');
        localStorage.setItem('activeLanguage', 'mk');
        window.location.reload();
    }

    _onSetLanguageToAlbanian = () =>{
        strings.setLanguage('al');
        localStorage.setItem('activeLanguage', 'al');
        window.location.reload();

    }

    _onSetLanguageToEnglish = () => {
        strings.setLanguage('en');
        localStorage.setItem('activeLanguage', 'en');
        window.location.reload();
    }

    itemDisplay = (item, index) => {
        return (
            <NavLink key={index}
                     style={{color: "white", fontSize: "initial", textDecoration:"none"}}
                     to={item.path}
                     onClick={() => this.setActiveItem(item.label)}
                     className={item.label === this.state.active ? 'menu_menu item active' : 'item'}>
                                                <span><img key={index} className="img-icon ml-1" style={{background:"#034377"}}
                                                           src={require(`../../assets/images/dashboard_icons/${item.imgSrc}`)}
                                                           alt=""/></span>
                {" "}{item.label}
            </NavLink>
        );
    };
    getUserDetails(){
        UserRepository.getUserDetails().then(response=>{
            this.setState({
                firstName:response.data[2],
                lastName:response.data[3]
            })
        })
    }
    componentDidMount(){
        this.getUserDetails();
    }

    render() {
        let itemsAdmin = [
            {
                label: strings.courses,
                imgSrc: 'icon-audit.png',
                path: '/'
            },
            {
                label: strings.statistics,
                imgSrc: 'icon-point-contact.png',
                path: '/statistics'
            },{
                label: strings.users,
                imgSrc: 'icon-point-contact.png',
                path: '/users'
            },
            {
                label: strings.certificates,
                imgSrc: 'icon-investment.png',
                path: '/certificates'
            },
            {
                label: strings.categories,
                imgSrc: 'icon-investment.png',
                path: '/category'
            }
        ];
        let itemsUser = [
            {
                label: strings.availableCourses,
                imgSrc: 'dostapniobuki.png',
                path: '/'
            },
            {
                label: strings.myCourses,
                imgSrc: 'moiobuki.png',
                path: '/mycourses',
               
            }
        ];

        let items = null;
        if (this.state.userRole === "ROLE_ADMIN") {
            items = itemsAdmin.map(this.itemDisplay);
        }
        if (this.state.userRole === "ROLE_USER") {
            items = itemsUser.map(this.itemDisplay);
        }

        return (
            <div>
                <nav className="navbar navbar-expand-lg navbar navbar-dark py-4" style={{ backgroundImage: `url(${navbarMenu})`}}>
                   <div className='col-xl-3 col-lg-2 col-md-1'></div>
                    <img src={this.props.image}/>
                    <a className='navbar-brand p-2'  style={{color:"white", fontWeight:"bold", textTransform:"uppercase", fontSize:"30px"}}>{this.props.title}</a>
                    <button type="button" className="navbar-toggler " data-toggle="collapse"
                            data-target="#navbarCollapse1" >
                        <span className="navbar-toggler-icon" ></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse1">
                        <div className="navbar-nav ml-auto mt-md-3" style={{flexDirection:"column"}}>
                        <div style={{color:"#034377", fontSize:"18px", fontWeight:"bold"}}><img style={{width:"35px"}} src={userIcon}/> {this.state.firstName}  {this.state.lastName}</div>
                        <div className='ml-2' style={{display:"flex",flexDirection:"column"}}>{items}</div>
                        </div>
                        <div className="navbar-nav" style={{display:"flex",flexDirection:"column",alignSelf:"flex-end"}}>
                        <span>
                            <p className="nav-item nav-link" style={{
                                color: "white",
                                fontSize: "initial",
                                display: "inline",
                                verticalAlign: "middle"
                            }}>
                                <Link to={'/changepassword'} className="nav-item nav-link"
                                      style={{
                                          color: "#034377",
                                          fontSize: "initial",
                                          display: "inline",
                                          verticalAlign: "middle",
                                          fontWeight:"bold"
                                      }}>
                                  <i class="fa fa-key" aria-hidden="true"></i> {strings.changePassword}
                                </Link>

                            </p>
                        </span>
                            <span>
                                <a className="item ml-md-3"
                                   style={{color: "#034377", fontSize: "initial", textDecoration:"none", fontWeight:"bold"}}
                                   href="/logout"> <i class="fa fa-sign-out" aria-hidden="true" style={{color:"#034377"}}></i> {strings.logout}</a>
                        </span>

                        </div>
                        <div id="emptyDiv" className='col-xl-3' style={{display:"flex", alignSelf:"flex-end"}}>
                            <button className="navButton" onClick={this._onSetLanguageToMacedonian}>MK</button>
                            <button className="navButton" onClick={this._onSetLanguageToAlbanian}>SQ</button>
                            <button className="navButton" onClick={this._onSetLanguageToEnglish}>EN</button>
                        </div>


                    </div>
                </nav>

            </div>
        );
    }
}

export default Nav;