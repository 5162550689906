export const languages = {
    mk: {
        login: "Најави се",
        register: "Регистрирај се",
        forgotPassword: "Ја заборавив лозинката ?",
        email: "Е-пошта",
        password: "Лозинка",
        courses:"Обуки",
        users:"Корисници",
        certificates:"Сертификати",
        categories:"Категории",
        statistics:"Статистика за корисници",
        myCourses:"Мои обуки",
        availableCourses:"Достапни обуки",
        available:"ДОСТАПНИ",
        coursesHeader:"ОБУКИ",
        coursesHeader1:"ОБУКИ",
        my:"МОИ",
        changePassword:"Промени лозинка",
        logout:"Одјава",
        name: "Име",
        filterBy: "Филтрирај според",
        account: "Немам налог?",
        lastName:"Презиме",
        telephoneNumber:"Телефонски број",
        institution:"Институција",
        age:"Возраст",
        specialty:"Специјалност",
        workPosition:"Работна позиција",
        licenseNumber:"Број на лиценца",
        doctorOfMedicine:"Доктор по медицина",
        doctorOfDentalMedicine:"Доктор по дентална медицина",
        nurse:"Медицинска сестра",
        healthAssociate:"Здравствен соработник",
        other:"Друго",
        supportedBy:"Поддржано од",
        resetPassword:"Ресетирај Лозинка",
        resetPasswordText:"Внесете ја вашата адреса за е-пошта и ние ќе испратиме линк за ресетирање на лозинката.",
        acceptTerms:"Ги прифаќам условите за користење",
        send:"Испрати",
        haveAccount:"Веќе имам налог ?",
        validationEmail:"Внесете валидна емаил адреса и лозинка.",
        resetPasswordValidation:"Вашето барање не е испратено, испративте неточна емаил адреса.",
        fillAllFields:"Пополнете ги сите полиња",
        failRegistration:"Неуспешна регистрација!",
        successfullRegister:" Ви благодариме што се регистриравте.",
        successfullRegisterText:"Пред да можете да се најавите, треба да ја активирате вашата сметка со кликнување на врската за активирање во е-поштата што штотуку ви ја испративме!",
        successfullResetPass:"Вашето барање е испратено, проверете ја електронската пошта",
        previous:"претходна",
        next:"следна",
        add:"Додади",
        edit:"Измени",
        macedonianTitle:"Наслов-Македонски",
        albanianTitle:"Наслов-Албански",
        englishTitle:"Наслов-Англиски",
        required:"(Задолжително)",
        close:"Затвори",
        save:"Зачувај",
        macedonianName:"Име - Македонски",
        albanianName:"Име - Албански",
        englishName:"Име - Англиски",
        requiredField:"Задолжително поле",
        successfullyAddedCategory:"Успешно додадена категорија",
        errorCategoryMessage:"Неуспешно додадена категорија",
        errorCategoryMessages:"Неуспешно додадена категорија - полето за Македонски јазик е задолжително",
        successfulEditMessage:"Измените се зачувани",
        errorEditMessage:"Неуспешно променета категорија, категоријата веќе постои",
        uploadedCertificate:"Луѓе кои прикачиле сертификат:",
        receivedCertificate:"Луѓе кои добиле потпишан сертификат:",
        sign:"Потпиши",
        lastname:"Презиме",
        uploadDate:"Датум на прикачување",
        actions:"Акции",
        back:"Откажи" ,
        signCertificate:"Потпиши сертификат",
        delete:"Избриши",
        deleteUploadedCertificate:"Избриши прикачен сертификат",
        successfulSignMessage:"Успешно потпишан сертификат",
        errorSignMessage:"Неуспешно потпишување сертификат",
        successfulDeleteMessage:"Успешно избришано",
        errorDeleteMessage:"Неуспешно бришење",
        successfulSignCertificates:"Успешно потпишани сертификати",
        errorSignCertificates:"Неуспешно потпишани сертификати",
        areYouSureMessage:"Дали сте сигурни дека сакате да ги потпишете непотпишаните сертификати ?",
        areYouSureMessageDelete:"Дали сте сигурни дека сакате да го избришете прикачениот сертификат ?",
        areYouSureMessageSign:"Дали сте сигурни дека сакате да го потпишете прикачениот сертификат ?",
        newPassword:"Нова лозинка:",
        confirmNewPassword:"Повтори ја лозинката:",
        change:"Промени",
        successfulPassMessage:"Лозинката е успешно променета",
        errorPassMessage:"Промената на лозинката е неуспешна",
        invalidPassMessage:"Немате внесено ништо во полето за лозинка/повтори лозинка или лозинките не се совпаѓаат!",
        validDate:"Внесете точен датум",
        imageValid:"Внесете соодветен формат на слика .jpg/.jpeg/.png",
        languageOfCourse:"Јазик на обуката",
        dateCourse:"Дата од/до",
        fromDate:"Од датум",
        toDate:"До датум",
        search:"Барај",
        signUp:"Пријави се",
        signUpModalQuestion:"Дали сте сигурни дека сакате да ја следите оваа обука ?",
        cancel:"Откажи",
        successfullyAddedCourse:"Успешно ја додадовте обуката",
        unsuccessfullyAddedCourse:"Неуспешно",
        macedonian:"Македонски",
        albanian:"Албански",
        english:"Англиски",
        category:"Категорија",
        surname: "Презиме",
        course: "Обука",
        action: "Акција",
        courseDescription: "Опис",
        courseLink: "Линк",
        courseStatus: "Статус",
        coursePoints: "Бодови",
        courseSortNo: "Број на прикажување",
        courseLanguage: "Јазик",
        courseLanguageChoose: "-Одберете јазик на обуката-",
        courseLanguageMk: "Македонски",
        courseLanguageAl: "Албански",
        courseLanguageEn: "Англиски",
        addButton: "Додади",
        editButton: "Измени",
        nextPage: "следна",
        prevPage: "претходна",
        courseStatusOptionChoose: "-Изберете статус на обуката-",
        courseStatusOptionActive: "Активен",
        courseStatusOptionInactive: "Неактивен",
        courseCertificate: "Прикачување сертификат",
        courseCertificateAddChoose: "-Изберете прикачување сертификат-",
        courseCertificateAddCanUpload: "Корисникот може да прикачи сертификат",
        courseCertificateAddCannotUpload: "Корисникот не може да прикачи сертификат",
        courseEvaluation: "Одберете тип на евалуација",
        courseEvaluationType: "-Изберете тип на евалуација-",
        coursePreEvaluation: "Pre-евалуација",
        coursePostEvaluation: "Post-евалуација",
        courseNoImage: "Обуката нема слика!",
        evaluationPre: "Пред",
        evaluationPost: "Потоа",
        evaluationBoth: "Двете",
        evaluationNothing: "Ништо",
        courseCategory: "Категорија",
        courseCategoryChoose: "-Изберете категорија на обуката-",
        dateFrom: "Почетен датум",
        dateTo: "Краен датум",
        courseManual: "Упатство",
        courseImage: "Слика",
        currentImageName: "Име на моментална слика: ",
        invalidImage: "Внесете соодветна слика",
        toastSuccess: "Успешно додадена обука",
        toastFail: "Неуспешно додадена обука",
        footer: "Министерство за Здравство - Управа за електронско здравство",
        editToastSuccess: "Измените се зачувани",
        statisticsRegisteredUsers: "Регистрирани корисници",
        statisticsMedicalDoctors: "Медицински доктори",
        statisticsDentalDoctors: "Доктори по дентална медицина",
        statisticsNurses: "Медицински сестри",
        statisticsHealthColaborators: "Здравствени соработници",
        statisticsOther: "Друго",
        statisticsReminder: "Испратете потсетување за достапни курсеви на",
        title: "Наслов",
        message: "Порака",
        jobPosition: "Работна позиција...",
        sendButton: "Испрати",
        cancelButton: "Откажи",
        sendReminder: "Испрати потсетување",
        sendReminderConfirmation: "Дали сте сигурни дека сакате да го испратите потсетувањето?",
        toastReminderSuccess: "Успешно пратено",
        toastReminderFail: "Праќањето е неуспешно",
        chooseWorkGroup: "Изберете работна група",
        uploadButton: "Прикачи",
        documentUploadSuccess: "Документот е успешно додаден",
        documentUploadFail: "Поставувањето е неуспешно!",
        documentUploadUnsigned: "Немате додадено потпишан документ!",
        uploadDocument: "Изберете документ",
        downloadCertificate: "Превземи сертификат",
        uploadCertificate: "Прикачи сертификат од обуката",
        openCourse: "Отвори обука",
        courseAccredited: "Акредитирано со ",
        points: "бода",
        toastOneDateFail: "Обуката не може да содржи еден датум!",
        noDate:"Нема датум",
        open: "Отвори",
        loadingCourses:"Вчитување обуки...",
        courseNoTemplate: "Обуката нема шаблон за сертификат",
        allowedTemplateFormat: "Дозволен формат: .docx",
        currentTemplate: "Моментален шаблон: ",
        template: "Шаблон за Сертификат",
        invalidTemplate: "Погрешен формат на шаблонот",
        allowedImageFormats: "Дозволени формати за слика: .jpg .jpeg .png",
        generateCertificate:"Генерирај сертификат",
        all:"Сите",
        selectCourse:"Ве молиме изберете обука",
        selectUsers:"Ве молиме изберете корисници за генерирање сертификати",
        successfullyGeneratedCertificates:"Успешно генерирани сертификати",
        noTemplate:"Обуката нема шаблон за генерирање сертификати"
    },


    al: {
        login: "Kyçu",
        register: "Regjistrohu",
        forgotPassword: "Keni harruar fjalëkalimin?",
        email: "Е-mail adresa",
        password: "Fjalëkalimi",
        courses:"Trajnimet",
        users:"Përdoruesit",
        certificates:"Certifikatat",
        categories:"Kategoritë",
        statistics:"Statistika e përdoruesve",
        myCourses:"Trajnimet e mia",
        availableCourses:"Trajnimet në dispozicion",
        available:"TRAJNIMET",
        coursesHeader:"E MIA",
        coursesHeader1:"NË DISPOZICION",
        my:"TRAJNIMET",
        changePassword:"Ndrysho fjalëkalimin",
        logout:"Ç'kyçu",
        name: "Emri",
        filterBy: "Filtro sipas",
        save: "Ruaj",
        account: "Nuk kam llogari?",
        lastName:"Mbiemri",
        telephoneNumber:"Numri i telefonit",
        institution:"Institucioni",
        age:"Mosha",
        specialty:"Specializimi",
        workPosition:"Pozita e punës",
        licenseNumber:"Numri i licensës",
        doctorOfMedicine:"Doktor i mjekësisë",
        doctorOfDentalMedicine:"Doktor i mjekësisë dentare",
        nurse:"Infermiere",
        healthAssociate:"Bashkëpunëtor shëndetësor",
        other:"Të tjera",
        footer:"Ministria e Shëndetësisë – Drejtoria për shëndetësi elektronike",
        supportedBy:"Мbështetur nga",
        resetPassword:"Rivendosni Fjalëkalimin",
        resetPasswordText:"Shtoni adresën tuaj të postës elektronike dhe ne do t'ju dërgojmë një link për ta rivendosur fjalëkalimin tuaj.",
        acceptTerms:"I pranoj kushtet e përdorimit",
        send:"Dërgo",
        haveAccount:"Tashmë kam një llogari?",
        validationEmail:"Shtoni një adresë të vlefshme emaili dhe fjalëkalim.",
        resetPasswordValidation:"Kërkesa juaj nuk është dërguar, ju keni shtuar një adresë emaili të pasaktë.",
        fillAllFields:"Plotësoni të gjitha fushat",
        failRegistration:"Regjistrimi dështoi!",
        successfullRegister:"Faleminderit që u regjistruat.",
        successfullRegisterText:"Përpara se të mund të kyçeni, duhet të aktivizoni llogarinë tuaj duke klikuar në linkun e aktivizimit që sapo jua kemi dërguar në emailin tuaj!",
        successfullResetPass:"Kërkesa juaj është dërguar, kontrolloni emailin tuaj",
        previous:"e mëparshme",
        next:"e ardhshme",
        add:"Shto",
        edit:"Ndrysho",
        macedonianTitle:"Titulli - në gjuhën maqedonase",
        albanianTitle:"Titulli - në gjuhën shqipe",
        englishTitle:"Titulli - në gjuhën angleze",
        required:"(E detyrueshme)",
        close:"Mbylle",
        macedonianName:"Emri - në gjuhën maqedonase",
        albanianName:"Emri - në gjuhën shqipe",
        englishName:"Emri - në gjuhën angleze",
        requiredField:"Fushë e detyrueshme",
        successfullyAddedCategory:"Kategoria u shtua me sukses",
        errorCategoryMessage:"Shtimi i kategorisë ishte i pasuksesshëm",
        errorCategoryMessages:"Shtimi i kategorisë ishte i pasuksesshëm - fusha për gjuhën maqedonase është e detyrueshme",
        successfulEditMessage:"Ndryshimet tuaja janë ruajtur",
        errorEditMessage:"Gabim - kjo kategori tashmë ekziston",
        uploadedCertificate:"Persona që kanë ngarkuar certifikatë:",
        receivedCertificate:"Persona që kanë pranuar certifikatë të nënshkruar:",
        sign:"Nënshkruaj",
        lastname:"Mbiemri",
        course:"Trajnimi",
        uploadDate:"Data e ngarkimit",
        actions:"Veprimet",
        back:"Anulo",
        signCertificate:"Nënshkruaj certifikatë",
        delete:"Fshij",
        deleteUploadedCertificate:"Fshij certifikatën e ngarkuar",
        successfulSignMessage:"Certifikata u nënshkrua me sukses",
        errorSignMessage:"Nënshkrimi i certifikatës ishte i pasuksesshëm",
        successfulDeleteMessage:"Fshierja ishte e suksesshme",
        errorDeleteMessage:"Fshierja ishte e pasuksesshme",
        successfulSignCertificates:"Certifikatat u nënshkruan me sukses",
        errorSignCertificates:"Nënshkrimi i certifikatave ishte i pasuksesshëm",
        areYouSureMessage:"Jeni të sigurt që dëshironi ti nënshkruani certifikatat e panënshkruara?",
        areYouSureMessageDelete:"Jeni të sigurt që dëshironi ti fshini certifikatat e ngarkuara?",
        areYouSureMessageSign:"Jeni të sigurt që dëshironi ti nënshkruani certifikatat e ngarkuara?",
        newPassword:"Fajlëkalimi i ri:",
        confirmNewPassword:"Përsërit fjalëkalimin:",
        change:"Ndrysho",
        successfulPassMessage:"Fjalëkalimi u ndryshua me sukses",
        errorPassMessage:"Ndryshimi i fjalëkalimit ishte i pasuksesshëm",
        invalidPassMessage:"Nuk keni shënuar asgjë në fushën për fjalëkalim/përsërit fjalëkalimin ose fjalëkalimet nuk përputhen!",
        validDate:"Shtoni datë të saktë",
        imageValid:"Shtoni format valid/të vlefshëm të fotografisë .jpg/.jpeg/.png",
        languageOfCourse:"Gjuha e trajnimit",
        dateCourse:"Data nga/deri",
        fromDate:"Data nga",
        toDate:"Data deri",
        search:"Kërko",
        signUp:"Regjistrohu",
        signUpModalQuestion:"Jeni të sigurt që dëshironi ta ndiqni këtë trajnim?",
        cancel:"Аnulo",
        successfullyAddedCourse:"Trajnimi u shtua me sukses",
        unsuccessfullyAddedCourse:"Shtimi i trajnimit ishte i pasuksesshëm",
        macedonian:"Мaqedonase",
        albanian:"Shqipe",
        english:"Аngleze",
        category:"Kategoria",
        surname: "Mbiemri",
        action: "Veprimi",
        courseDescription: "Përshkrimi",
        courseLink: "Linku",
        courseStatus: "Statusi",
        coursePoints: "Pikët",
        courseSortNo: "Numri rendor",
        courseLanguage: "Gjuha",
        courseLanguageChoose: "-Zgjedhni gjuhën e trajnimit-",
        courseLanguageMk: "Maqedonase",
        courseLanguageAl: "Shqipe",
        courseLanguageEn: "Angleze",
        addButton: "Shto",
        editButton: "Ndrysho",
        nextPage: "e ardhshme",
        prevPage: "e mëparshme",
        courseStatusOptionChoose: "-Zgjedhni statusin e trajnimit-",
        courseStatusOptionActive: "Aktiv",
        courseStatusOptionInactive: "Joaktiv",
        courseCertificate: "Bashkangjitja e certifikatës",
        courseCertificateAddChoose: "-Zgjedhni bashkangjitjen e certifikatës-",
        courseCertificateAddCanUpload: "Përdoruesi mund të bashkangjesë certifikatë",
        courseCertificateAddCannotUpload: "Përdoruesi nuk mund të bashkangjesë certifiaktë",
        courseEvaluation: "Zgjedhni llojin e evaluimit/vlerësimit",
        courseEvaluationType: "-Zgjedhni llojin e evaluimit/vlerësimit-",
        coursePreEvaluation: "Pre-evaluim",
        coursePostEvaluation: "Post-evaluim",
        courseNoImage: "Trajnimi nuk ka fotografi të bashkangjitur!",
        evaluationPre: "Para",
        evaluationPost: "Pas",
        evaluationBoth: "Të dyja",
        evaluationNothing: "Asnjë",
        courseCategory: "Kategoria",
        courseCategoryChoose: "-Zgjedhni kategorinë e trajnimit-",
        dateFrom: "Data e fillimit",
        dateTo: "Data e mbarimit",
        courseManual: "Udhëzuesi",
        courseImage: "Fotografia",
        currentImageName: "Emri i fotografisë aktuale: ",
        invalidImage: "Shtoni fotografi të duhur",
        toastSuccess: "Trajnimi u shtua me sukses",
        toastFail: "Shtimi i trajnimit ishte i pasuksesshëm",
        editToastSuccess: "Ndryshimet janë ruajtur",
        statisticsRegisteredUsers: "Përdoruesit e regjistruar",
        statisticsMedicalDoctors: "Doktorët medicinal",
        statisticsDentalDoctors: "Doktorë të mjekësisë dentare",
        statisticsNurses: "Infermiere",
        statisticsHealthColaborators: "Bashkëpunëtorë shëndetësorë",
        statisticsOther: "Të tjerë",
        statisticsReminder: "Dërgoni njoftim për trajnimet e disponueshme",
        title: "Titulli",
        message: "Mesazhi",
        jobPosition: "Pozita e punës...",
        sendButton: "Dërgo",
        cancelButton: "Anulo",
        sendReminder: "Dërgo njoftimin",
        sendReminderConfirmation: "Jeni të sigurt që dëshironi ta dërgoni njoftimin?",
        toastReminderSuccess: "U dërgua me sukses",
        toastReminderFail: "Dërgimi ishte i pasuksesshëm",
        chooseWorkGroup: "Zgjedhni një grup punues",
        uploadButton: "Ngarko",
        documentUploadSuccess: "Dokumenti u shtua me sukses",
        documentUploadFail: "Shtimi i dokumentit ishte i pasuksesshëm",
        documentUploadUnsigned: "Nuk keni shtuar dokument të nënshkruar!",
        uploadDocument: "Zgjedhni një fajll (skedar)",
        downloadCertificate: "Shkarko certifikatën",
        uploadCertificate: "Ngarko certifikatën nga trajnimi",
        openCourse: "Hap trajnimin",
        points: "pikë",
        courseAccredited: "Akredituar me ",
        toastOneDateFail: "Kursi nuk mund të ketë vetëm një datë!",
        noDate:"Nuk ka datë",
        open: "Hape",
        loadingCourses:"Trajnimet po ngarkohen...",
        courseNoTemplate: "Trajnimi nuk ka shabllon për certifikatë",
        allowedTemplateFormat: "Formati i lejuar: .docx",
        currentTemplate: "Shablloni i tanishëm: ",
        template: "Shabllon për certifikatë",
        invalidTemplate: "Format i gabuar i shabllonit",
        allowedImageFormats: "Formatet e lejuara për fotografi: .jpg .jpeg .png",
        generateCertificate:"Gjeneroni certifikatë",
        all:"Të gjithë",
        selectCourse:"Ju lutemi zgjedhni trajnimin",
        selectUsers:"Ju lutemi zgjedhni përdoruesit për gjenerimin e certifikatave",
        successfullyGeneratedCertificates:"Certifikatat u gjeneruan me sukses",
        noTemplate:"Trajnimi nuk ka shabllon për gjenerimin e certifikatës!"
    },

    en: {
        login: "Login",
        register: "Register",
        forgotPassword: " Forgot password?",
        email: "E-mail",
        password: "Password",
        courses:"Courses",
        users:"Users",
        certificates:"Certificates",
        categories:"Categories",
        statistics:"User Statistics",
        myCourses:"My courses",
        availableCourses:"Available courses",
        available:"AVAILABLE",
        my:"MY",
        coursesHeader:"COURSES",
        courseHeader1: "COURSES",
        changePassword:"Change password",
        logout:"Logout",
        name: "Name",
        filterBy: "Filter by",
        save:"Save",
        account: "Don't have account?",
        lastName:"Last Name",
        telephoneNumber:"Phone number",
        institution:"Institution",
        age:"Age",
        specialty:"Speciality",
        workPosition:"Work Position",
        licenseNumber:"License number",
        doctorOfMedicine:"Doctor of Medicine",
        doctorOfDentalMedicine:"Doctor of Dental Medicine",
        nurse:"Nurse",
        healthAssociate:"Health Associate",
        other:"Other",
        footer:"Ministry of Health - Electronic Health Administration",
        supportedBy:"Supported by",
        resetPassword:"Reset Password",
        resetPasswordText:"Enter your email address and we will send you a link to reset your password",
        acceptTerms:"I accept the terms of use",
        send:"Send",
        haveAccount:"Already have an account?",
        validationEmail:"Enter a valid email address and password.",
        resetPasswordValidation:"Your request has not been sent, you have sent an incorrect email address.",
        fillAllFields:"Fill in all the fields",
        failRegistration:"Register failed!",
        successfullRegister:"Thank you for registering.",
        successfullRegisterText:"Before you can log in, you need to activate your account by clicking on the activation link on the email we just sent you!",
        successfullResetPass:"Your request has been sent, check your email",
        previous:"previous",
        next:"next",
        add:"Add",
        edit:"Edit",
        macedonianTitle:"Title-Macedonian",
        albanianTitle:"Title-Albanian",
        englishTitle:"Title-English",
        required:"(Required)",
        close:"Close",
        macedonianName:"Name - Macedonian",
        albanianName:"Name - Albanian",
        englishName:"Name - English",
        requiredField:"Required field",
        successfullyAddedCategory:"Successfully added category",
        errorCategoryMessage:"Failed to add new category",
        successfulEditMessage:"Changes saved",
        errorEditMessage:"Error - this category already exists",
        errorCategoryMessages:"Failed to add new category - Please fill in the required field",
        uploadedCertificate:"People that uploaded a certificate:",
        receivedCertificate:"People that received a signed certificate:",
        sign:"Sign",
        lastname:"Last name",
        course:"Course",
        uploadDate:"Upload date",
        actions:"Actions",
        back:"Back",
        signCertificate:"Sign certificate",
        delete:"Delete",
        deleteUploadedCertificate:"Delete uploaded certificate",
        successfulSignMessage:"The certificate is signed",
        errorSignMessage:"Failed to sign",
        successfulDeleteMessage:"Successfully deleted",
        errorDeleteMessage:"Failed to delete the certificate",
        successfulSignCertificates:"Successfully signed certificates",
        errorSignCertificates:"Failed to sign the certificates",
        areYouSureMessage:"Are you sure you want to sign the unsigned certificates?",
        areYouSureMessageDelete:"Are you sure you want to delete the uploaded certificates?",
        areYouSureMessageSign:"Are you sure you want to sign the uploaded certificates?",
        newPassword:"New password:",
        confirmNewPassword:"Repeat new password:",
        change: "Change",
        successfulPassMessage:"Your password is changed",
        errorPassMessage:"Failed to change the password",
        invalidPassMessage:"You have not entered anything in the password field / repeat password or the passwords do not match!",
        validDate:"Enter a valid date format",
        imageValid:"Enter a valid image format .jpg/.jpeg/.png",
        languageOfCourse:"Course language",
        dateCourse:"Date from / to",
        fromDate:"From date",
        toDate:"To date",
        search:"Search",
        signUp:"Sign up",
        signUpModalQuestion:"Are you sure you want to follow this course?",
        cancel:"Cancel",
        successfullyAddedCourse:"You have successfully added the course",
        unsuccessfullyAddedCourse:"Unsuccessfully added course",
        macedonian:"Macedonian",
        albanian:"Albanian",
        english:"English",
        category:"Category",
        surname: "Surname",
        action: "Action",
        courseDescription: "Description",
        courseLink: "Link",
        courseStatus: "Status",
        coursePoints: "Points",
        courseSortNo: "Display number",
        courseLanguage: "Language",
        courseLanguageChoose: "-Choose course language-",
        courseLanguageMk: "Macedonian",
        courseLanguageAl: "Albanian",
        courseLanguageEn: "English",
        addButton: "Add",
        editButton: "Edit",
        nextPage: "next",
        prevPage: "prev",
        courseStatusOptionChoose: "-Pick Course Status-",
        courseStatusOptionActive: "Active",
        courseStatusOptionInactive: "Inactive",
        courseCertificate: "Certificate Upload",
        courseCertificateAddChoose: "-Choose Certificate Upload-",
        courseCertificateAddCanUpload: "The user can upload certificate",
        courseCertificateAddCannotUpload: "The user cannot upload certificate",
        courseEvaluation: "Choose Evaluation Type",
        courseEvaluationType: "-Choose Evaluation Type-",
        coursePreEvaluation: "Pre-evaluation",
        coursePostEvaluation: "Post-evaluation",
        courseNoImage: "The Course has no Image attached!",
        evaluationPre: "Pre",
        evaluationPost: "Post",
        evaluationBoth: "Both",
        evaluationNothing: "Nothing",
        courseCategory: "Category",
        courseCategoryChoose: "-Choose Course Category-",
        dateFrom: "Starting Date",
        dateTo: "Ending Date",
        courseManual: "Manual",
        courseImage: "Image",
        currentImageName: "Current Image File Name: ",
        invalidImage: "Upload appropriate image file!",
        toastSuccess: "Successfully Added to Courses",
        toastFail: "Adding course failed!",
        editToastSuccess: "Change was successfully made!",
        statisticsRegisteredUsers: "Registered Users",
        statisticsMedicalDoctors: "Medical Doctors",
        statisticsDentalDoctors: "Dental Doctors",
        statisticsNurses: "Nurses",
        statisticsHealthColaborators: "Health coworkers",
        statisticsOther: "Other",
        statisticsReminder: "Send reminder to",
        title: "Title",
        message: "Message",
        jobPosition: "Job position...",
        sendButton: "Send",
        cancelButton: "Cancel",
        sendReminder: "Send Reminder",
        sendReminderConfirmation: "Are you sure you want to send the reminder?",
        toastReminderSuccess: "Sent Successfully",
        toastReminderFail: "Sending Failed!",
        chooseWorkGroup: "Choose work group!",
        uploadButton: "Upload",
        documentUploadSuccess: "Document uploaded successfully",
        documentUploadFail: "Uploading failed!",
        documentUploadUnsigned: "You have no document added!",
        uploadDocument: "Choose a document",
        downloadCertificate: "Download Certificate",
        uploadCertificate: "Upload Course Certificate",
        openCourse: "Open Course",
        courseAccredited: "Accredited with ",
        points: "points",
        toastOneDateFail: "The course cannot have just one date!",
        noDate:"No date",
        open: "Open",
        loadingCourses:"Loading courses...",
        courseNoTemplate: "Course has no certificate template!",
        allowedTemplateFormat: "Allowed template format: .docx",
        currentTemplate: "Current template file: ",
        template: "Certificate Template",
        invalidTemplate: "Invalid template format",
        allowedImageFormats: "Allowed image formats: .jpg .jpeg .png",
        generateCertificate:"Generate certificate",
        all:"All",
        selectCourse:"Please select course",
        selectUsers:"Please choose users for generating certificates",
        successfullyGeneratedCertificates:"Successfully generated certificates",
        noTemplate:"Course does not have template for generating certificate!"

    }
};