import React, {Component} from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import ReactPaginate from "react-paginate";
import {toast} from "react-toastify";
import {ButtonToolbar} from "react-bootstrap";
import CrudModal from "../Courses/CrudModal";
import EditCategory from "./EditCategory";
import CategoryRepository from "../../repository/CategoryRepository";
import {strings} from "../Localization/Localization";
class Category extends Component{

    state = {
        categories: [],
        // pageCount: 1
    }

    componentDidMount() {
        this.fetchData();
    }

    render() {
        return (
            <>

                <div className="container">
                    <div className="mt-3 mb-1"><Link to="/category/add" className="btn btn-primary btn-sm button_category"><FontAwesomeIcon
                        icon={faPlusCircle} style={{paddingRight: '4px'}}/>{strings.add}</Link></div>
                    <table className="table table-hover">
                        <thead>
                        <tr>
                            <th>Име на категорија - МКД</th>
                            <th>Emri i kategorisë - SHQ</th>
                            <th>Name of category - ENG</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.categories.map(this.renderCategoriesItem)}
                        </tbody>
                    </table>
                    <div>
                        <ReactPaginate
                            previousLabel={strings.previous}
                            nextLabel={strings.next}
                            breakLabel={'...'}
                            pageCount={this.state.pageCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={2}
                            onPageChange={this.handlePageClick}
                            breakClassName={'page-item'}
                            breakLinkClassName={'page-link'}
                            containerClassName={'pagination'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            activeClassName={'active'}
                        />
                    </div>
                </div>
            </>
        )
    }


    fetchData = (selectedPage= 0, size= 10) => {
        CategoryRepository.getCategories(selectedPage, size).then(res => {
            this.setState({
                categories: res.data.content,
                pageCount: res.data.totalPages
            });
            //debugger

        }).catch(err => {
            console.log(err)
        })
    }


    handlePageClick = (data) => {
        this.fetchData(data.selected);
    };


    onEdit = (entity) => {

        return CategoryRepository.editCategory(entity).then(res => {
            toast.success(strings.successfulEditMessage)
            this.fetchData();
        }).catch(err => toast.error(strings.errorEditMessage));
    };

    renderCategoriesItem = (category) => {
        return (
            <tr key={category.id}>
                <td>{category.nameMk}</td>
                <td>{category.nameAl}</td>
                <td>{category.nameEn}</td>
                <td colSpan={5}>
                    <ButtonToolbar>
                        <CrudModal entity={category}
                                   btnClass="btn-success btn-sm button_category"
                                   title={strings.edit}
                                   icon={"Edit"}
                                   onSubmit={this.onEdit}
                                   body={EditCategory}/>
                    </ButtonToolbar>
                </td>
            </tr>
        );
    }
}

export default Category;